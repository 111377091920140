@charset "UTF-8";
@font-face {
  font-family: "proxima nova";
  src: url("fonts/proximanova-regular-webfont.eot");
  src: url("fonts/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/proximanova-regular-webfont.woff") format("woff"), url("fonts/proximanova-regular-webfont.ttf") format("truetype"), url("fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "proxima nova";
  src: url("fonts/proximanova-bold-webfont.eot");
  src: url("fonts/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/proximanova-bold-webfont.woff") format("woff"), url("fonts/proximanova-bold-webfont.ttf") format("truetype"), url("fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
  font-weight: bold;
  font-style: normal; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@keyframes flip-down {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-90deg); } }

@keyframes flip-up {
  0% {
    transform: rotate(-90deg); }
  100% {
    transform: rotate(0); } }

@keyframes turn-in {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(-180deg); } }

@keyframes turn-out {
  0% {
    transform: rotate(-180deg); }
  100% {
    transform: rotate(0); } }

@keyframes show-down {
  0% {
    max-height: 0; }
  100% {
    max-height: 1000px; } }

@keyframes hide-up {
  0% {
    max-height: 1000px; }
  100% {
    max-height: 0; } }

* {
  box-sizing: border-box; }

html,
body {
  height: 100%;
  width: 100%; }

body {
  font-family: "proxima nova", "Helvetica", "Sans-Serif";
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.618;
  overflow-x: hidden; }

h1 {
  font-size: 36px;
  line-height: 1.25; }

h2 {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 9px; }

h3 {
  font-size: 24px;
  font-weight: normal; }

h4 {
  font-size: 18px;
  font-weight: normal; }

a {
  color: #28ade3;
  text-decoration: none; }
  a:hover {
    color: #136d92; }

strong {
  color: #000;
  font-weight: bold; }

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
Layout Rules

Styleguide 2.0
*/
.l-wrappermobile {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%; }

.l-course {
  display: flex;
  flex-direction: row; }
  .l-course .requirements-list {
    flex: 0 0 260px; }
  .l-course .content {
    flex: 1; }

/*
Chapters

Layout wrapper for chapter tiles

Markup:
<ul class="l-chapters">
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
  <li>
    <sg-insert>3.24</sg-insert>
  </li>
</ul>

Styleguide 2.5
*/
.l-chapters {
  margin-top: -9px;
  padding: 0 9px; }
  .l-chapters li {
    box-sizing: border-box;
    display: flex;
    padding: 9px; }
  @media (min-width: 400px) {
    .l-chapters {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap; }
      .l-chapters li {
        flex: 0 0 50%; } }
  @media (min-width: 960px) {
    .l-chapters li {
      flex: 0 0 33.33%; } }

/*
Container

Markup:
<div class="l-container">
</div>

Styleguide 2.7
*/
.l-container {
  padding: 18px; }

/*
Container Horizontal

Default - Sets a max width
.l-containerhorizontal-small - Smaller max width
.l-containerhorizontal-mediuam - Mediaum max width

Markup:
<div class="l-containerhorizontal {$modifiers}">Content</div>

Styleguide 2.1
*/
.l-containerhorizontal {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%; }
  @media (max-width: 959px) {
    .l-containerhorizontal.l-containerhorizontal-is-minpad {
      padding-left: 18px;
      padding-right: 18px; } }

.l-containerhorizontal-small {
  max-width: 100%; }
  @media (min-width: 960px) {
    .l-containerhorizontal-small {
      max-width: 540px; } }

.l-containerhorizontal-medium {
  max-width: 100%; }
  @media (min-width: 960px) {
    .l-containerhorizontal-medium {
      max-width: 700px; } }

/*
Container Vertical

Markup:
<div class="l-containervertical">
Content
</div>

Styleguide 2.6
*/
.l-containervertical {
  padding-bottom: 18px;
  padding-top: 18px; }
  @media (min-width: 960px) {
    .l-containervertical {
      padding-bottom: 36px;
      padding-top: 36px; } }

@media (min-width: 960px) {
  .l-containervertical-small {
    padding-bottom: 18px;
    padding-top: 18px; } }

@media (min-width: 860px) {
  .l-menu {
    align-items: center;
    display: flex;
    justify-content: flex-end; }
    .l-menu > * {
      margin-left: 18px; } }

.l-menu-secondary {
  display: flex; }
  .l-menu-secondary > * {
    flex: 1; }
  @media (min-width: 860px) {
    .l-menu-secondary > * {
      flex: 0 0 auto; } }

/*
Spacing

Sets top and bottom margins

Markup:
<div class="l-spacing">Content</div>

Styleguide 2.3
*/
.l-spacing {
  margin-bottom: 18px;
  margin-top: 18px; }

/*
Children Vertical

Sets bottom margins for each child.

Default - Each child except the last
.l-childrenvertical-all - Every child gets bottom margin

Markup:
<div class="l-childrenvertical">
  <p>Content</p>
  <p>Content</p>
  <p>Content</p>
</div>

Styleguide 2.4
*/
.l-childrenvertical > *:not(:last-child) {
  margin-bottom: 36px; }

.l-childrenvertical .l-childrenvertical-x-exclude {
  margin-bottom: 0; }

.l-verticalchilren-all > * {
  margin-bottom: 36px; }

.l-childrenvertical-small > *:not(:last-child) {
  margin-bottom: 18px; }

/*
Wrap

Auto generates left and right padding based on viewport

Default - The default use case
.l-wrap-spanmobile - No minimum padding for mobile

Markup:
<div class="l-wrap {$modifiers}">Content</div>

Styleguide 2.2
*/
.l-wrap {
  padding-left: 18px;
  padding-right: 18px; }
  @media (min-width: 960px) {
    .l-wrap {
      padding-left: calc(50% - 850px/2);
      padding-right: calc(50% - 850px/2); } }

@media (max-width: 649px) {
  .l-wrap-spanmobile {
    padding: 0; } }

/*
glossary

Layout wrapper glossary

Markup:
<div class="l-glossary">
  <div>glossary terms<div>
  <div>glossary definitions<div>
</div>

Styleguide 2.8
*/
@media (min-width: 650px) {
  .l-glossary {
    display: flex; } }

.clearfix:after {
  clear: both;
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden; }

.clearfix {
  display: inline-block; }

/* start commented backslash hack \*/
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

/* close commented backslash hack */
/*
Module Rules (App)

Styleguide 3.0
*/
/*
Bordered

Markup:
<div class="bordered">
Bordered
</div>

Styleguide 3.34
*/
.bordered {
  border: 1px solid #ccc; }

/*
Button

Default - Standard button styling
.button-footer - Buttons used in the footer element
.button-height-small - shorter button.
.button-marketing - For use on light backgrounds with marketing content
.button-marketing-light - For use on dark backgrounds with marketing content
.button-menu - When a button appears in the navigation
.button-signup - Rounded corners for auth views
.button-white - For use on a dark background.
.button-width-full - stretches the length of the available space.


Markup:
<button class="button {$modifiers}">Button</button>

Styleguide 3.1
*/
.button {
  appearance: none;
  background: #28ade3;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "proxima nova", "Helvetica", "Sans-Serif";
  font-size: 14px;
  font-weight: normal;
  padding: 18px 36px;
  text-align: center; }
  .button:hover {
    background: #198fbf;
    color: #fff; }
  .button.disabled {
    background: #696969; }

.button-white {
  background: #fff;
  color: #28ade3; }

.button-footer {
  background: #F2F2F2;
  color: #28ade3;
  text-transform: none; }
  @media (min-width: 950px) {
    .button-footer {
      border-radius: 3px;
      font-weight: bold;
      padding-bottom: 2.7px;
      padding-top: 2.7px; } }
  .button-footer:hover {
    color: #155a76; }

.button-menu {
  background: #fff;
  border-radius: 10px;
  box-shadow: #28ade3 0 0 0 1px inset;
  color: #28ade3;
  text-transform: none; }

.button-marketing {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  text-transform: none; }
  .button-marketing:hover {
    background: #fff;
    color: #28ade3; }

.button-marketing-light {
  background: #fff;
  border: 1px solid #28ade3;
  border-radius: 5px;
  color: #28ade3;
  font-size: 18px;
  text-transform: none; }
  .button-marketing-light:hover {
    background: #28ade3;
    border-color: #28ade3;
    color: #fff; }

.button-ppc {
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  padding: 13.5px;
  width: 100%; }
  @media (min-width: 650px) {
    .button-ppc {
      font-size: 24px;
      padding: 18px;
      width: 337px; } }

.button-outline {
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 18px;
  padding: 18px;
  width: auto; }
  .button-outline:hover {
    background-color: transparent; }

.button-signup {
  padding: 18px 54px;
  text-transform: none; }

.button-width-full {
  width: 100%; }

.button-height-small {
  padding: 9px 18px; }

/*
Centered

centers text.

Markup:
<div class="centered">I am centered</div>

Styleguide 3.46
*/
.centered {
  text-align: center; }

/*
Chapter

Default - Standard view
.disabled - No interaction
.locked - Locked out (some requirement not met)
.passed - Passed and complete

Markup:
<article class="chapter {$modifiers}">
    <header class="vid_header">
         <h2><a href="#">Title</a></h2>
    </header>
    <p class="status passed">Status</p>
    <a class="chapter_view chapter-image image" href="#" style="background-image: url('/images/tile-notifications.png');"></a>
</article>

Styleguide 3.24
*/
.chapter {
  align-items: stretch;
  background: #fff;
  display: flex;
  min-height: 286px;
  flex: 1;
  flex-direction: column;
  z-index: 20; }
  .chapter:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25); }
  .chapter header, .chapter .skills {
    padding: 9px; }
  .chapter h1 {
    font-size: 12px;
    line-height: 1em; }
  .chapter h2 {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2em;
    margin-bottom: 0; }
  .chapter p {
    font-size: 12px;
    line-height: 1.2em; }
  .chapter header {
    order: 3; }
  .chapter .status {
    background: #9e9e9e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    order: 1;
    padding: 4.5px 9px;
    z-index: 20; }
    .chapter .status span {
      order: 2; }
    .chapter .status.passed {
      background: #9aba38; }
    .chapter .status.inprogress {
      background: #28ade3; }
    .chapter .status.locked {
      background: #000; }
    .chapter .status.retake {
      background: #e75345; }
  .chapter .image {
    background-position: center center;
    background-size: cover;
    display: block;
    filter: grayscale(100%);
    flex: 1;
    height: 0;
    order: 2;
    height: 70%;
    width: 100%;
    z-index: 10; }
  .chapter .skills {
    background-color: #fff;
    border-top: 1px solid #F2F2F2;
    font-size: 12px;
    line-height: 1.2em;
    order: 4;
    z-index: 20; }
    .chapter .skills a {
      font-weight: normal; }
  .chapter.disabled {
    opacity: .5;
    pointer-events: none; }
  .chapter.locked {
    pointer-events: none; }
  .chapter.passed .image {
    filter: grayscale(0%); }

/*
Choices

Doherty Videos

Markup:
<section class="choices" id="options">
  <h2>Here are four types of attitudes that we have found in our research. Please read all four and then select which one best fits you at this time.</h2>
  <ul class="choice-list">
    <li class="choice-wrapper">
      <div class="choice">
        <p>I'm done with this relationship; it's too late now even if my partner were to make major changes.</p>
        <a class="button" data-option="done" data-type="option">Watch this Video</a>
      </div>
    </li>
    <li class="choice-wrapper">
      <div class="choice">
        <p>I have mixed feelings about the breakup; sometimes I think it's a good idea and sometimes I'm not sure.</p>
        <a class="button" data-option="mixed" data-type="option">Watch this Video</a>
      </div>
    </li>
    <li class="choice-wrapper">
      <div class="choice">
        <p>I would consider reconciling if my partner got serious about making major changes.</p>
        <a class="button" data-option="majorchanges" data-type="option">Watch this Video</a>
      </div>
    </li>
    <li class="choice-wrapper">
      <div class="choice">
        <p>I don't want this breakup, and I would work hard to get us back together.</p>
        <a class="button" data-option="dontwant" data-type="option">Watch this Video</a>
      </div>
    </li>
  </ul>
</section>

Styleguide 3.5
*/
.choices {
  padding: 0 9px; }
  .choices h2 {
    font-size: 14px;
    line-height: 1.5em;
    margin-bottom: 18px;
    padding: 0 9px; }
    @media (min-width: 960px) {
      .choices h2 {
        color: #28ade3;
        font-size: 30px;
        line-height: 1.2em; } }

.choice-wrapper {
  padding: 0.05px; }

.choice {
  background: #F2F2F2;
  box-sizing: border-box;
  padding: 18px;
  width: 100%;
  display: block;
  margin: 18px 0; }
  .choice input {
    vertical-align: middle; }

.choices-advance-button {
  text-align: right;
  margin: 18px 0; }

/*
Credits

Markup:
<section class="credits text-container l-containerhorizontal">
  <article>
    <h1>Center for Divorce Education</h1>
    <address>
      1005 E. State St., Suite G
      <br>Athens, OH 45701
    </address>
    <address>
      <br>Toll Free: 877-874-1365
    </address>
    <address>
      Web:
      <br><a href="http://www.divorce-education.com" target="_blank">http://www.divorce-education.com</a>
    </address>
  </article>
  <article>
    <h1>Credits</h1>
    <p>
      The videos shown are a revision of an earlier version, which won the Positive Parenting Award from the Children's Rights Council, the Telly Award, and the Model Program Award from the Substance Abuse and Mental Health Services Administration.
    </p>
    <h3>Executive Producer:</h3>
    <p>Donald Gordon, Ph.D.</p>
    <h3>Web Design and Development:</h3>
    <p>Concentric Sky, Eugene, Oregon</p>
    <h3>Video and Audio Development:</h3>
    <ul>
      <li>Sullivan Recording, Medford, Oregon</li>
      <li>MediaBrite</li>
    </ul>
    <h3>Author &amp; Exec. Producer:</h3>
    <h4>Donald A. Gordon, Ph.D.</h4>
    <p>Dr. Gordon is a child and family psychologist, a researcher and public speaker, and Emeritus Professor of Psychology from Ohio University</p>
    <p>The Center for Divorce Education would like to thank The Honorable Bruce Peterson (District Court Judge in Minnesota) and Dr. William Doherty (Professor in Dept. of Family Social Sciences, University of Minnesota) for their donation of time and contribution to the Introduction/Orientation section of this class and the section speaking to Alternatives to a Divorce also presented in the CIBO class.</p>
    <p>Copyright © 2007</p>
    <p>All rights reserved. No part of this product may be reproduced in any form, electronic or mechanical, including photography, recording, or any information storage or retrieval system, except as specified by the publisher in legal agreement.</p>
  </article>
</section>

Styleguide 3.6
*/
.credits {
  padding-bottom: 18px;
  padding-top: 18px; }
  @media (min-width: 960px) {
    .credits {
      display: flex; } }
  @media (min-width: 960px) {
    .credits article {
      flex: 1;
      padding: 18px; }
      .credits article:last-of-type {
        border-left: 1px solid #F2F2F2; } }

/*
Data Table

Markup:
<div class="datatable">
  <table>
    <thead>
      <tr>
        <th scope="row">Case Number</th>
        <th scope="row">Cumulative Score</th>
        <th scope="row">Time on Site</th>
        <th scope="row">Skills Practice</th>
        <th scope="row">State/County Requirements</th>
        <th scope="row">Survey</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>None</td>
        <td>5</td>
        <td>12hr 12min</td>
        <td>Complete</td>
        <td>Incomplete</td>
        <td>Incomplete</td>
      </tr>
    </tbody>
  </table>
</div>

Styleguide 3.25
*/
.datatable {
  margin: 0 18px 18px; }
  .datatable td,
  .datatable th {
    line-height: 1;
    padding-right: 36px;
    text-align: left; }
  .datatable td {
    font-size: 30px;
    padding-top: 9px; }

/*
Dialog

No example available, just placeholder for now.

Styleguide 3.26
*/
.ui-widget-overlay {
  background: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute; }

.ui-front {
  z-index: 101; }

.ui-dialog,
#modal {
  border: 10px solid rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow: hidden;
  z-index: 101; }
  .ui-dialog.modal-course,
  #modal.modal-course {
    text-align: left; }
  @media (max-width: 950px) {
    .ui-dialog,
    #modal {
      box-sizing: border-box;
      left: 0px !important;
      position: fixed !important;
      top: 0px !important;
      width: 100% !important; } }
  .ui-dialog .ui-dialog-titlebar,
  .ui-dialog #modal_header,
  #modal .ui-dialog-titlebar,
  #modal #modal_header {
    background: #fff;
    padding: 18px 18px 1px; }
    .ui-dialog .ui-dialog-titlebar button.ui-button,
    .ui-dialog #modal_header button.ui-button,
    #modal .ui-dialog-titlebar button.ui-button,
    #modal #modal_header button.ui-button {
      position: absolute;
      background: none;
      border: none;
      color: #28ade3;
      top: 10px;
      right: 10px;
      cursor: pointer; }
  .ui-dialog.modal-course #modal_header,
  #modal.modal-course #modal_header {
    margin-bottom: -1px;
    font-size: 32px; }
  .ui-dialog:not(.modal-course) .ui-dialog-content,
  .ui-dialog:not(.modal-course) #content,
  #modal:not(.modal-course) .ui-dialog-content,
  #modal:not(.modal-course) #content {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px; }
    @media (max-width: 950px) {
      .ui-dialog:not(.modal-course) .ui-dialog-content,
      .ui-dialog:not(.modal-course) #content,
      #modal:not(.modal-course) .ui-dialog-content,
      #modal:not(.modal-course) #content {
        overflow-y: auto !important; } }
  .ui-dialog.modal-course #content,
  #modal.modal-course #content {
    background: #fff;
    max-height: calc(100vh - 111px);
    overflow: auto;
    padding: 18px; }
  .ui-dialog #modal_header.reference-modal,
  #modal #modal_header.reference-modal {
    display: flex;
    justify-content: space-between;
    padding-bottom: 17px;
    padding-right: 80px; }
  .ui-dialog #close,
  #modal #close {
    position: absolute;
    background: none;
    border: none;
    color: #28ade3;
    top: 10px;
    right: 10px;
    cursor: pointer; }
  .ui-dialog h2,
  #modal h2 {
    line-height: 1.2em; }
  .ui-dialog p,
  #modal p {
    margin-bottom: 18px; }
  .ui-dialog.modal-course p,
  #modal.modal-course p {
    margin: 0; }
  .ui-dialog .button,
  #modal .button {
    margin-bottom: 9px; }
  .ui-dialog .county_name,
  #modal .county_name {
    font-weight: bold; }

#overlay {
  background: rgba(0, 0, 0, 0.65);
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 101; }

#modal {
  display: none;
  left: 50%;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 40vw;
  z-index: 101; }
  #modal.modal-course {
    overflow-y: inherit; }
  @media (max-width: 950px) {
    #modal {
      transform: none;
      width: 100vw; } }

/*
Errors Global

Markup:
<div class="errors-global">Please correct the errors below.</div>

Styleguide 3.27
*/
.errors-global {
  background: #f26c4f;
  color: #fff;
  font-size: 14px;
  margin: 0 9px 18px;
  padding: 18px; }
  @media (min-width: 950px) {
    .errors-global {
      margin-left: 0;
      margin-right: 0; } }

/*
Error 404

Markup:
<article class="error404">
  <h1>404 Error</h1>
  <h2>It appears there was a breakdown in communication.</h2>
  <p>Either the URL was mistyped, never existed, or once existed but was mysteriously lost.</p>
  <a class="button" href="">Take me home</a>
  <p>Want to communicate better?<br> <a href="">Check out Skills Practice</a></p>
</article>

Styleguide 3.7
*/
.error404 {
  background: #155a76 url("images/error404.png") center center;
  background-size: cover;
  color: #fff;
  padding: 54px 18px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px; }
  .error404 h1 {
    line-height: 1; }
  .error404 h2 {
    font-size: 24px;
    line-height: 1.16; }
  .error404 p {
    font-size: 18px;
    line-height: 1.3; }
  .error404 > a {
    font-size: 18px;
    width: 100%; }
  .error404 > * {
    margin-bottom: 9px; }
  @media (min-resolution: 2dppx) {
    .error404 {
      background-image: url("images/error404-2x.png"); } }
  @media (min-width: 650px) {
    .error404 {
      align-items: center;
      background-image: url("images/error404-desktop.png");
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100vh - 80px); }
      .error404 h1 {
        font-size: 64px; }
      .error404 h2 {
        font-size: 30px; }
      .error404 > a {
        text-shadow: none;
        width: auto; }
      .error404 > * {
        margin-bottom: 18px;
        max-width: 440px; } }
  @media (min-width: 650px) and (min-resolution: 2dppx) {
    .error404 {
      background-image: url("images/error404-desktop-2x.png"); } }

/*
FAQ

Markup:
<article class="faqs">
  <header class="page-title page-title-centered">
    <h1>FAQs</h1>
  </header>
  <ol>
    <li><strong>I can’t see any pictures on the screen, just words. What’s wrong?
    </strong>
    <section><p>You are using an incompatible web browser. Install and use <a href="http://www.google.com/chrome/" target="_blank">Google Chrome</a>.</p></section>
    </li>
    <li>
      <strong>When I log into the program, it keeps telling me to update the Adobe Flash
        Player, but I’ve already done this once. What’s wrong?
      </strong>
      <section><p>You are using an incompatible web browser. Install and use <a href="http://www.google.com/chrome/" target="_blank">Google Chrome</a>.</p></section>
    </li>
  </ol>
</article>

Styleguide 3.8
*/
.faqs {
  background: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 0 18px; }
  @media (min-width: 960px) {
    .faqs {
      margin-bottom: 36px;
      margin-top: 36px; } }
  .faqs ol {
    padding: 0 18px; }
  .faqs li {
    font-weight: bold;
    list-style: decimal inside;
    margin-bottom: 18px; }
  .faqs section {
    font-size: 14px;
    font-weight: normal; }

/*
Fee Waiver

Place holder

Markup:
  <ol class="feeWaiver">
    <li>
      <h1>Capture document</h1>
      <div class="feeWaiver-x-contentWrapper">
        <div>
          <h2>Take photo</h2>
          Position mobile device over document, take and save photo to device.
        </div>
        <div class="feeWaiver-x-fontsize-large">OR</div>
        <div>
          <h2>Scan document</h2>
          Scan file and save file to device.
        </div>
      </div>
    </li>
    <li>
      <h1>Move document (optional)</h1>
      <div class="feeWaiver-x-contentWrapper">
        <div>
          <h2>Email file</h2>
          Email photo to yourself and download to device.
        </div>
        <div class="feeWaiver-x-fontsize-large">OR</div>
        <div>
          <h2>Transfer file</h2>
            Upload file to device.
        </div>
      </div>
    </li>
    <li>
      <h1>Upload document</h1>
      <div>Select "Choose File" below and find the document file.</div>
    </li>
  </ol>

Styleguide 3.51
*/
#upload-instructions {
  padding-bottom: 9px; }

.feeWaiver {
  border: solid #979797 1px;
  counter-reset: item;
  padding: 18px;
  position: relative; }
  .feeWaiver h1 {
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    margin-left: 26px;
    margin-bottom: 9px; }
  .feeWaiver li {
    background: url("images/circle_smallGrey.svg");
    background-repeat: no-repeat;
    font-size: 18px;
    line-height: 22px;
    list-style: none !important; }
    .feeWaiver li::before {
      content: counter(item, decimal);
      counter-increment: item;
      position: absolute;
      left: 23px; }
    .feeWaiver li:not(:last-child) {
      margin-bottom: 18px; }
    .feeWaiver li div {
      font-size: 12px;
      line-height: 14px; }
      .feeWaiver li div:not(.feeWaiver-x-contentWrapper) {
        padding-left: 27px; }
      .feeWaiver li div:not(:last-of-type) {
        padding-bottom: 10px; }
      .feeWaiver li div h2 {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        padding: 0;
        margin: 0; }
  .feeWaiver .feeWaiver-x-fontsize-large {
    font-size: 18px;
    line-height: 22px; }
  @media (min-width: 860px) {
    .feeWaiver h1 {
      display: block; }
    .feeWaiver li .feeWaiver-x-contentWrapper {
      align-items: center;
      display: flex; }
      .feeWaiver li .feeWaiver-x-contentWrapper div {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 18px;
        max-width: 249px; }
        .feeWaiver li .feeWaiver-x-contentWrapper div:first-child {
          padding-left: 27px; } }

/*
Footer

Markup:
<footer class="footer">
  <div class="l-containerhorizontal">
    <a href="http://www.divorce-education.com/" target="_blank" class="logo"><img src="/images/logo-footer.png" alt="alt=" the="" center="" for="" divorce="" education="" logo""=""></a>
    <section>
      <dl>
        <dt>Toll Free</dt>
        <dd>877-874-1365</dd>
        <dt class="visually-hidden">Email</dt>
        <dd><a href="mailto:staff@divorce-education.com">staff@divorce-education.com</a></dd>
      </dl>
      <nav>
        <ul>
          <li><a href="/" class="button button-footer">Home</a></li>
          <li><a href="/course" class="button button-footer">Course</a></li>
          <li><a href="/reference/credits/" class="button button-footer">Credits</a></li>
          <li><a href="/reference/faq/" class="button button-footer">FAQ</a></li>
        </ul>
      </nav>
    </section>
  </div>
</footer>

Styleguide 3.9
*/
.footer {
  box-shadow: 0 -1px 0 0 rgba(27, 35, 39, 0.15);
  padding-bottom: 18px; }
  @media (min-width: 950px) {
    .footer {
      padding-bottom: 18px;
      padding-top: 18px; } }
  @media (min-width: 950px) {
    .footer .l-containerhorizontal {
      align-items: center;
      display: flex;
      justify-content: space-between; } }
  .footer .logo {
    display: block;
    margin: 18px;
    opacity: .8; }
    @media (min-width: 950px) {
      .footer .logo {
        margin-bottom: 0;
        margin-top: 0;
        opacity: .3; } }
    .footer .logo img {
      display: block;
      max-width: 100%; }
    .footer .logo:hover {
      opacity: 1; }
      @media (min-width: 950px) {
        .footer .logo:hover {
          opacity: .5; } }
  @media (min-width: 950px) {
    .footer section {
      align-items: flex-end;
      display: flex;
      flex-direction: column; } }
  .footer dl {
    color: #ccc;
    margin: 0 18px 9px; }
    @media (min-width: 950px) {
      .footer dl {
        display: flex;
        line-height: 1em;
        white-space: nowrap; } }
    .footer dl a {
      font-weight: normal; }
      @media (min-width: 950px) {
        .footer dl a {
          font-weight: bold; } }
  .footer dt {
    float: left;
    margin-right: 4px; }
    @media (min-width: 950px) {
      .footer dt {
        float: none; } }
  .footer dt:after {
    content: ': '; }
  @media (min-width: 950px) {
    .footer dd {
      box-shadow: #f6f3ee -2px 0 0 0 inset;
      margin-right: 4px;
      padding-right: 6px; } }
  .footer dd:last-of-type {
    box-shadow: none;
    margin-right: 0;
    padding-right: 0; }
  @media (min-width: 950px) {
    .footer ul {
      display: flex;
      margin-right: 18px; }
      .footer ul li {
        margin-left: 9px; } }
  .footer .button {
    margin-bottom: 5px;
    width: 100%; }
    @media (min-width: 950px) {
      .footer .button {
        margin-bottom: 0; } }
  .footer.has-requirements {
    padding-bottom: 72px; }

/*
Fork

Markup:
<ul class="fork">
  <li>
    <h1 class="fork-x-complete">You've Watched This</h1>
    <div>
      <img srcset="http://placehold.it/240x284 http://placehold.it/120x142" src="http://placehold.it/120x142" width="120" height="142" alt="{{description}}">
    </div>
    <p>Mom calls Dad and explains why she denied his request for extra time previously. Dad reacts angrily and refuses her request. Mom tells him she will not grant extra time the next time he asks for it. </p>
    <button class="button" disabled>Watch This Video</button>
  </li>
  <li>
    <div>
      <img srcset="http://placehold.it/240x284 http://placehold.it/120x142" src="http://placehold.it/120x142" width="120" height="142" alt="{{description}}">
    </div>
    <p>Mom calls Dad and presents her reasons for wanting Casey home early, as well as for keeping the skates at her house.</p>
    <button class="button">Watch This Video</button>
  </li>
</ul>

Styleguide 3.10
*/
.fork {
  padding: 0 18px 18px; }
  @media (min-width: 960px) {
    .fork {
      display: flex;
      justify-content: center; } }
  .fork ::after {
    clear: both;
    content: '';
    display: table; }
  .fork > * {
    background: #fff;
    box-sizing: content-box;
    margin-bottom: 18px;
    min-height: 142px;
    padding: 9px;
    position: relative; }
    @media (max-width: 959px) {
      .fork > * {
        margin: 0 auto 18px; } }
    @media (min-width: 960px) {
      .fork > * {
        flex: 1 1 0; }
        .fork > *:first-child {
          margin-right: 18px; } }
  .fork div {
    float: left;
    margin-right: 9px;
    z-index: 10; }
    .fork div img {
      display: block; }
  .fork p {
    font-size: 12px; }
  .fork button {
    clear: both;
    margin-top: 9px;
    padding-bottom: 13.5px;
    padding-top: 13.5px;
    width: 100%; }
  .fork .fork-choice {
    max-width: 355px; }
    .fork .fork-choice button {
      font-weight: bold;
      font-size: 18px; }
  .fork .fork-image {
    width: 355px;
    height: 176px; }
    .fork .fork-image img {
      height: 100%;
      width: 100%; }
  .fork .fork-text {
    font-size: 14px;
    margin-top: 9px; }
  .fork .fork-x-complete {
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    line-height: 23px;
    color: #fff;
    display: flex;
    justify-content: center;
    height: 176px;
    width: 355px;
    margin: 0;
    position: absolute;
    left: 9px;
    top: 9px;
    right: 9px;
    z-index: 50; }
    .fork .fork-x-complete::before {
      background: url("images/fork-done.png");
      background-size: contain;
      content: '';
      display: block;
      height: 23px;
      margin-right: 9px;
      width: 23px; }

/*
Form

Forms are monolithic and not well modularized at this point. There's a big todo to change them.

Default - This is the standard form styling
.form-inline - This submodule puts inputs and labels side by side

Markup:
<form action="" method="POST" class="form {$modifiers}">
  <fieldset class="user-information">
    <legend>1. Create your Account</legend>
    <div class="form-row ">
      <label for="id_name">Name</label>
      <div class="value">
        <input type="text" placeholder="John Doe" name="name" id="id_name">
        <p>Please enter your name exactly as you want it to appear on your certificate of completion.</p>
      </div>
    </div>
    <div class="form-row ">
      <label for="id_email">
        Email:
      </label>
      <div class="value">
        <input type="text" name="email" id="id_email">
        <p>This is helper text for this input.</p>
      </div>
    </div>
    <div class="form-row success">
      <label for="id_language">
        Language Version
      </label>
      <div class="value">
        <select name="language" id="id_language">
          <option value="en" selected="selected">English</option>
          <option value="es">Spanish</option>
        </select>
      </div>
      <p class="form-x-warningmessage"><em>Please note: Your County requires prior permission from the Court in order to complete an online class. Contact the Clerk of Courts at 123-123-4567.</em></p>
    </div>
    <div class="form-row checkbox">
      <div class="value">
        <input type="checkbox" name="print_version" id="id_print_version">
      </div>
      <label for="id_print_version">
        <p>Yes, for an additional $11.50, please ship me Print Versions of the two Workbooks. Access to digital version already included in purchase price. <strong>(Total price with print versions: $51.45)</strong></p>
      </label>
    </div>
  </fieldset>
  <fieldset>
    <legend>Have a Coupon?</legend>
    <div class="form-row form-row-is-valid">
      <label for="coupon">Coupon Code</label>
      <div class="value">
        <input type="text" name="name" id="coupon" value="WV2017">
        <p class="form-row-x-success">Discount applied.</p>
        <button class="button button-height-small button-marketing-light" type="button">Apply</button>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>Order Summary</legend>
    <div class="form-x-summary">
        <sg-insert>3.53</sg-insert>
        <p class="form-x-warningmessage"><em>Please note: Your County requires prior permission from the Court in order to complete an online class. Contact the Clerk of Courts at 123-123-4567.</em></p>
    </div>
  </fieldset>
  <fieldset>
    <div class="radio-group-payment">
      <ul>
        <li><label for="id_payment_type_0" class="active"><input type="radio" id="id_payment_type_0" value="authnet" name="payment_type"> <p>Pay with major credit cards:</p><img src="/images/cc-icons.png" alt="Pay with major credit cards: Visa, Mastercard, American Express, or Discover"></label></li>
      </ul>
    </div>
  </fieldset>
  <fieldset class="submit">
    <button type="submit" class="button">Finalize Your Purchase</button>
  </fieldset>
  <sg-insert>5.3</sg-insert>
</form>


Styleguide 3.2
*/
.form {
  margin-bottom: 36px; }
  @media (min-width: 950px) {
    .form {
      padding: 0 18px; } }
  .form div label {
    padding-left: 9px; }
  .form div ul {
    margin-bottom: 18px;
    margin-left: 22.5px; }
  .form div li:not(:last-child) {
    padding-bottom: 9px; }
  .form fieldset:not(:last-child) {
    margin-bottom: 18px; }
  .form fieldset > p {
    margin: 0 18px 18px; }
    @media (min-width: 950px) {
      .form fieldset > p {
        margin-left: 0;
        margin-right: 0; } }
  .form .submit-row {
    margin-bottom: 0;
    margin-left: 0; }
  .form legend,
  .form .legend {
    border-bottom: 1px solid #f6f3ee;
    box-sizing: border-box;
    color: #000;
    display: block;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 9px;
    padding: 0 18px 9px;
    width: 100%; }
    @media (min-width: 950px) {
      .form legend,
      .form .legend {
        color: #1b2327;
        padding-left: 0;
        padding-right: 0; } }
    .form legend span,
    .form .legend span {
      color: #696969;
      display: block;
      font-size: 14px;
      margin-top: 9px; }
  .form .submit {
    padding: 0 18px; }
    @media (min-width: 950px) {
      .form .submit {
        padding-left: 0;
        padding-right: 0; } }
    .form .submit p {
      color: #696969;
      margin-bottom: 9px; }
  .form .button {
    box-sizing: border-box;
    font-size: 18px;
    margin-bottom: 18px;
    text-transform: none;
    width: 100%; }
  .form .incomplete-form {
    opacity: .5;
    pointer-events: none; }
  .form .form-x-note {
    font-size: 24px;
    margin-bottom: 18px;
    padding: 0 18px 9px; }
  .form .form-x-note-important {
    font-size: 24px;
    margin: 18px 0;
    padding: 0 18px;
    text-align: center; }
  .form .form-x-links {
    padding: 0 18px;
    text-align: center; }
    .form .form-x-links a {
      font-weight: normal; }
  .form .form-x-summary {
    padding-left: 18px;
    padding-right: 18px; }
  .form .form-x-warningmessage {
    color: #f26c4f;
    font-size: 18px;
    line-height: 1.2;
    padding-top: 18px; }
    .form .form-x-warningmessage em {
      font-style: italic;
      font-weight: bold; }
  @media (min-width: 950px) {
    .form .form-x-summary {
      padding-left: 30%; } }

.form-nomargin {
  margin: 0; }

.form-row {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  padding: 0 18px;
  position: relative;
  /* File inputs are styled as default on page load, and custom classes are added via js. */ }
  @media (min-width: 950px) {
    .form-row {
      padding-left: 0;
      padding-right: 0; } }
  .form-row .button {
    margin-bottom: 0;
    margin-top: 18px;
    width: auto; }
  .form-row .value {
    display: flex;
    flex-wrap: wrap; }
    .form-row .value p {
      color: #696969;
      line-height: 1.2;
      margin-top: 4.5px;
      width: 100%; }
    .form-row .value .form-row-x-success {
      color: #9aba38; }
    .form-row .value .form-row-x-error {
      color: #e75345; }
  .form-row label,
  .form-row .label {
    color: #696969;
    display: block;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 0 5px;
    position: relative; }
  .form-row input:focus,
  .form-row select:focus {
    box-shadow: 0 0 5px 0 #28ade3;
    outline: none; }
  .form-row [type="text"],
  .form-row [type="email"],
  .form-row [type="password"],
  .form-row [type="tel"],
  .form-row select,
  .form-row textarea {
    appearance: none;
    background: #F2F2F2;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    color: #1b2327;
    display: block;
    font-family: "proxima nova", "Helvetica", "Sans-Serif";
    font-size: 18px;
    padding: 9px;
    width: 100%; }
  .form-row :disabled {
    opacity: .5; }
  .form-row select {
    background-image: url("images/form-row-select.svg");
    background-position: calc(100% - 9px) center;
    background-repeat: no-repeat;
    margin-top: 9px;
    padding-right: 27px; }
    @media (min-width: 950px) {
      .form-row select {
        margin-top: 0; } }
  .form-row img {
    display: block;
    flex: 1;
    height: auto;
    margin-top: 5px;
    position: absolute;
    right: 18px;
    top: -5px; }
  .form-row.form-row-is-valid [type="text"],
  .form-row.form-row-is-valid [type="email"],
  .form-row.form-row-is-valid [type="password"],
  .form-row.form-row-is-valid [type="tel"],
  .form-row.form-row-is-valid select,
  .form-row.form-row-is-valid textarea {
    background-image: url("images/formrow-valid.png");
    background-position: calc(100% - 18px) center;
    background-repeat: no-repeat;
    padding-right: 61px; }
  .form-row.checkbox {
    flex-direction: row; }
    .form-row.checkbox .value {
      width: 25px; }
    .form-row.checkbox label {
      flex: 1; }
      .form-row.checkbox label p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 18px; }
        .form-row.checkbox label p:first-child {
          color: #000;
          font-size: 18px;
          line-height: 1.2;
          margin-bottom: 0; }
        .form-row.checkbox label p:last-child {
          margin-bottom: 0; }
  .form-row.radio li {
    margin-bottom: 9px; }
  .form-row.radio label {
    align-items: center;
    display: flex;
    line-height: 1em;
    margin: 0; }
  .form-row.radio input {
    margin: -2px 9px 0 0; }
  .form-row.short {
    flex-direction: row;
    flex-wrap: wrap; }
    .form-row.short label {
      width: 100%; }
    .form-row.short [type="text"],
    .form-row.short [type="tel"] {
      margin-right: 9px;
      width: 90px; }
  .form-row.success [type="text"],
  .form-row.success [type="email"],
  .form-row.success [type="tel"] {
    background: white url("images/form-success.png") 95% center no-repeat;
    padding-right: 10%; }
  .form-row.error [type="text"],
  .form-row.error [type="tel"],
  .form-row.error [type="password"] {
    background: rgba(242, 108, 79, 0.1) url("images/form-error.png") 95% center no-repeat;
    border: 1px solid #f26c4f;
    padding-right: 10%; }
    @media (min-width: 950px) {
      .form-row.error [type="text"],
      .form-row.error [type="tel"],
      .form-row.error [type="password"] {
        margin-bottom: 5px; } }
  .form-row.error img {
    display: none; }
  .form-row.form-row-stripe .value {
    background: #F2F2F2;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    color: #1b2327;
    display: block;
    font-family: "proxima nova", "Helvetica", "Sans-Serif";
    font-size: 18px;
    padding: 9px; }
  .form-row.form-row-stripe.success .value {
    background: #f6f3ee url("images/form-success.png") 95% center no-repeat;
    padding-right: 10%; }
  .form-row.form-row-stripe.error .value {
    border: 1px solid #f26c4f; }
  .form-row.form-row-file input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .form-row.form-row-file label {
    /* Label contains filename. Don't want to limit its width. */
    width: inherit; }
    .form-row.form-row-file label * {
      /* Pointer events on descendant elements should bubble up to the label. */
      pointer-events: none; }
  .form-row.form-row-file .button-label {
    /* A label styled like a button. */
    cursor: pointer;
    background-color: #fff;
    color: #28ade3;
    border: 1px solid #28ade3;
    border-radius: 8px;
    padding: 9px 18px;
    text-align: center; }
    .form-row.form-row-file .button-label:focus, .form-row.form-row-file .button-label:hover {
      outline: 1px dotted #28ade3;
      /* Intentionally override for webkit browsers. Non-webkit will use line above. */
      outline: -webkit-focus-ring-color auto 5px; }
    @media (min-width: 950px) {
      .form-row.form-row-file .button-label {
        margin: 0; } }
  .form-row.form-row-file.error .button-label {
    border-color: #f26c4f; }

@media (min-width: 950px) {
  .form-inline .form-row {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap; }
    .form-inline .form-row img {
      display: none; } }
  @media (min-width: 950px) and (min-width: 950px) {
    .form-inline .form-row.short label {
      width: 28%; } }

@media (min-width: 950px) {
    .form-inline .form-row.form-row-aligncenter {
      align-items: center;
      flex-direction: column; }
  .form-inline label {
    margin: 10.8px 2% 0 0;
    text-align: right;
    width: 28%; }
  .form-inline .value {
    width: 70%; }
  .form-inline .errorlist {
    margin-left: 30%;
    width: 70%; }
  .form-inline .button {
    border-radius: 5px; }
  .form-inline .checkbox {
    align-items: flex-start; }
    .form-inline .checkbox .value {
      flex: 1;
      justify-content: flex-end;
      margin-right: 9px; }
    .form-inline .checkbox input {
      margin-right: 9px; }
    .form-inline .checkbox label {
      flex: 2;
      margin-right: 0;
      margin-top: 0;
      text-align: left; }
  .form-inline div label {
    padding-left: 9px; }
  .form-inline div ul {
    margin-bottom: 18px;
    margin-left: 126px;
    margin-top: 9px; }
  .form-inline div li:not(:last-child) {
    padding-bottom: 0; }
  .form-x-note {
    text-align: right; } }

.form-row-submit {
  align-items: center;
  display: flex;
  flex-direction: row; }
  .form-row-submit .form-row-submit-x-button {
    flex: 0 0 auto;
    margin-right: 18px; }
    .form-row-submit .form-row-submit-x-button .button {
      margin: 0 !important; }
  .form-row-submit .form-row-submit-x-message {
    display: none;
    flex: 1 1 100%;
    font-size: 18px; }
  .form-row-submit.form-row-submit-is-success .form-row-submit-x-message {
    color: #9aba38;
    display: block; }

.radio-group-payment {
  margin-bottom: 18px;
  overflow: hidden; }
  .radio-group-payment ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0 !important; }
    @media (min-width: 950px) {
      .radio-group-payment ul {
        align-items: stretch;
        flex-direction: row; } }
  .radio-group-payment li {
    margin-bottom: 9px;
    padding: 0 18px; }
    @media (min-width: 950px) {
      .radio-group-payment li {
        align-items: stretch;
        display: flex;
        flex: 1;
        margin-right: 9px;
        padding-left: 0;
        padding-right: 0; } }
  @media (min-width: 950px) {
    .radio-group-payment li:last-of-type {
      margin-right: 0; } }
  .radio-group-payment label {
    align-items: center;
    background: #fff;
    border: 1px solid #28ade3;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 18px 0;
    position: relative;
    text-align: center; }
    @media (min-width: 950px) {
      .radio-group-payment label {
        min-height: auto; } }
    .radio-group-payment label:hover, .radio-group-payment label:focus, .radio-group-payment label.active {
      background: #28ade3; }
      .radio-group-payment label:hover h1,
      .radio-group-payment label:hover h3,
      .radio-group-payment label:hover p, .radio-group-payment label:focus h1,
      .radio-group-payment label:focus h3,
      .radio-group-payment label:focus p, .radio-group-payment label.active h1,
      .radio-group-payment label.active h3,
      .radio-group-payment label.active p {
        color: #fff; }
  .radio-group-payment h1 {
    color: #28ade3;
    font-weight: bold;
    font-size: 62.4px;
    line-height: 1em; }
    .radio-group-payment h1 span {
      display: block;
      font-weight: normal;
      font-size: 24px;
      line-height: 1em; }
  .radio-group-payment table {
    margin: 0 auto; }
    .radio-group-payment table input {
      display: block; }
  .radio-group-payment p {
    color: #1b2327;
    font-size: 12.6px;
    line-height: 1.5em;
    margin: 5px 0; }
    .radio-group-payment p:last-child {
      margin-bottom: 0; }
    .radio-group-payment p span {
      display: block;
      font-size: 18px;
      line-height: 1em; }
  .radio-group-payment [type="radio"] {
    left: -9999px;
    position: absolute; }

/*
Form Survey

Overrides for the survey form type (has a border and some different layout options based on screen size)
TODO: This needs to become its own module

Markup:
<ul class="survey-progress progressbar">
  <li id="progress-demographics" class="current progressbar-item">1. Demographics</li>
  <li id="progress-case" class="progressbar-item">2. Case Background</li>
  <li id="progress-family" class="progressbar-item">3. Family Dynamics</li>
  <li id="progress-course" class="progressbar-item">4. Course Feedback</li>
</ul>
<form action="" class="form form-survey">
  <fieldset id="demographics">
    <legend>1. Demographics</legend>
    <div class="field date form-row required">
      <label>What year were you born in?</label>
      <div class="value"><input id="id_date_2-answer" type="text" name="date_2-answer" maxlength="4"></div>
      <ul class="errorlist" style="display: none;">
        <li>This field is required</li>
      </ul>
    </div>
    <div class="field multiple-choice form-row required">
      <label>What gender are you?</label>
      <div class="value"><ul>
        <li><label for="id_mc_15-answer_0"><input type="radio" id="id_mc_15-answer_0" value="67" name="mc_15-answer"> Male</label></li>
        <li><label for="id_mc_15-answer_1"><input type="radio" id="id_mc_15-answer_1" value="68" name="mc_15-answer"> Female</label></li>
        <li><label for="id_mc_15-answer_2"><input type="radio" id="id_mc_15-answer_2" value="69" name="mc_15-answer"> Other</label></li>
        <li><label for="id_mc_15-answer_3"><input type="radio" id="id_mc_15-answer_3" value="70" name="mc_15-answer"> Prefer not to answer</label></li>
      </ul></div>
      <ul class="errorlist" style="display: none;">
        <li>This field is required</li>
      </ul>
    </div>
  </fieldset>
  <section class="submit">
    <button class="button" id="next-section">Next Section</button>
    <input class="button" id="submit" type="submit" value="Continue" style="display: none;">
  </section>
</form>

Styleguide 3.2.1
*/
@media (min-width: 950px) {
  .form-survey {
    border: 1px solid #F2F2F2;
    border-bottom: 0;
    padding: 0; }
    .form-survey legend {
      display: none; }
    .form-survey .form-scale, .form-survey .form-row {
      border-bottom: 1px solid #F2F2F2; }
    .form-survey .form-row {
      padding: 18px; }
    .form-survey .submit {
      border-bottom: 1px solid #F2F2F2;
      padding: 0 18px; } }

/*
Form Scale

This is used for a scaling set of radio options built in a table

Markup:
<form action="" class="form form-scale">
  <fieldset>
    <div class="field scalerequired">
      <h2 class="form-scale-heading">The following questions refer to you, your co-parent involved, and your mutual child(ren). Select the option that best describes your situation.</h2>
      <div class="scale_responses">
        <table>
          <thead>
            <tr>
              <th class="label" scope="row"> &nbsp; </th>
              <th scope="col" class="value">Always</th>
              <th scope="col" class="value">Often</th>
              <th scope="col" class="value">Sometimes</th>
              <th scope="col" class="value">Rarely</th>
              <th scope="col" class="value">Never</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="label" scope="row"><label>Do you feel friendly toward your former partner?</label>
              <ul class="errorlist" style="display: none;"><li>This field is required.</li></ul>                    </th>
              <td class="value">
                <label for="id_statement_21-answer_1">
                  <input type="radio" id="id_statement_21-answer_1" name="statement_21-answer" value="27">
                  <span>Always</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_21-answer_2">
                  <input type="radio" id="id_statement_21-answer_2" name="statement_21-answer" value="28">
                  <span>Often</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_21-answer_3">
                  <input type="radio" id="id_statement_21-answer_3" name="statement_21-answer" value="29">
                  <span>Sometimes</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_21-answer_4">
                  <input type="radio" id="id_statement_21-answer_4" name="statement_21-answer" value="30">
                  <span>Rarely</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_21-answer_5">
                  <input type="radio" id="id_statement_21-answer_5" name="statement_21-answer" value="31">
                  <span>Never</span>
                </label>
              </td>
            </tr>
            <tr>
              <th class="label" scope="row"><label>Do your children feel friendly toward your former partner?</label>
              <ul class="errorlist" style="display: none;"><li>This field is required.</li></ul>                    </th>
              <td class="value">
                <label for="id_statement_22-answer_1">
                  <input type="radio" id="id_statement_22-answer_1" name="statement_22-answer" value="27">
                  <span>Always</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_22-answer_2">
                  <input type="radio" id="id_statement_22-answer_2" name="statement_22-answer" value="28">
                  <span>Often</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_22-answer_3">
                  <input type="radio" id="id_statement_22-answer_3" name="statement_22-answer" value="29">
                  <span>Sometimes</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_22-answer_4">
                  <input type="radio" id="id_statement_22-answer_4" name="statement_22-answer" value="30">
                  <span>Rarely</span>
                </label>
              </td>
              <td class="value">
                <label for="id_statement_22-answer_5">
                  <input type="radio" id="id_statement_22-answer_5" name="statement_22-answer" value="31">
                  <span>Never</span>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </fieldset>
</form>

Styleguide 3.2.2
*/
.form-scale {
  padding: 0 18px;
  margin-bottom: 18px; }
  @media (min-width: 960px) {
    .form-scale {
      padding-bottom: 18px;
      padding-top: 18px; } }
  .form-scale table {
    width: 100%; }
  .form-scale thead {
    display: none; }
    @media (min-width: 960px) {
      .form-scale thead {
        display: block; }
        .form-scale thead th {
          text-align: center;
          padding: 0; } }
  @media (min-width: 960px) {
    .form-scale tbody .label {
      padding: 18px 0; } }
  .form-scale th {
    text-align: left; }
  .form-scale tr {
    display: flex;
    flex-direction: column;
    padding-bottom: 18px; }
    @media (min-width: 960px) {
      .form-scale tr {
        border-bottom: 1px solid #f6f3ee;
        flex-direction: row;
        padding-bottom: 0; }
        .form-scale tr:last-child {
          border-bottom: none; }
        .form-scale tr label {
          width: 100%; }
        .form-scale tr .label {
          flex: 5 0 40%; }
        .form-scale tr .value {
          flex: 1 0 12%;
          padding: 18px 0;
          text-align: center; }
        .form-scale tr span {
          display: none; } }
  .form-scale .label, .form-scale .value {
    color: #696969;
    font-size: 18px; }
    @media (min-width: 960px) {
      .form-scale .label, .form-scale .value {
        align-items: center;
        color: #1b2327;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        justify-content: center; } }
  .form-scale .label {
    margin-bottom: 9px; }
  .form-scale .form-scale-heading {
    color: #696969;
    font-size: 24px;
    line-height: 1.2em; }

.errorlist {
  list-style: none;
  z-index: 10; }
  .errorlist li {
    color: #f26c4f;
    font-size: 14px;
    z-index: 100; }

/*
Form item description

This is an additional description within a form. It provides extra information about
a form item.

Markup:
  <div class="form-row form-itemdescription">
    <h2>Recommend to Co-Parent</h2>
    <p>Enter your Co-Parent’s email below and we’ll send them an email inviting them to sign up for Children in Between on your behalf. </p>
  </div>


Styleguide 3.2.3
*/
.form-itemdescription {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  padding-bottom: 10px; }
  .form-itemdescription h2 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px; }
  .form-itemdescription p {
    font-size: 12px;
    line-height: 14px; }

.form-x-Recommend-coparent {
  font-size: 18px;
  line-height: 27px;
  margin-left: auto;
  margin-right: auto; }

.form-rollup {
  border: 0;
  max-height: 0px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  transition: max-height .5s; }

.form-rollup-display-visible {
  border: 0;
  height: auto;
  margin: 0;
  max-height: 300px;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/*
Form as a button

Markup:
<div class="form form-inline">
  <div class="form-row form-row-aligncenter">
    <button id="buyForCoParent" class="button">Buy Children in Between for your Co-Parent</button>
    <p id="showFormButton" class="form-x-Recommend-coparent">or<a>Recommend to Co-Parent</a></p>
  </div>
</div>

Styleguide 3.2.4
*/
/*
Form Row (Stripe)

Default - Standard input type
.success - Shows the field was filled out correctly
.error - Shows a field error

Markup:
<form class="form">
  <fieldset>
    <legend>4. Secure Payment</legend>
    <div class="form-row form-row-stripe {$modifiers}">
      <label for="formrow">Label:</label>
      <div class="value" id="card-element">
        <span>[stripe code]</span>
      </div>
      <p class="form-row-instructions">These are field-level instructions</p>
    </div>
  </fieldset>
</form>

Styleguide 3.2.5
*/
/*
Global Notification

Markup:
<div class="global-notifications">
  <div class="notification l-containerhorizontal" id="notifications">
    <h4>CDE's Children In Between requires Google Chrome browser. <span class="error"><a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Download Chrome</a></span></h4>
    <h4>Some none error notification <span><a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Got It</a></span></h4>
  </div>
</div>

Styleguide 3.14
*/
.global-notifications {
  background: #155a76;
  width: 100%;
  position: relative;
  z-index: 10; }
  .global-notifications .notification {
    padding: 0 18px; }
    .global-notifications .notification strong {
      color: #fff; }
    .global-notifications .notification ul li {
      list-style: none;
      color: #fff;
      padding: 10px 0; }
    .global-notifications .notification h4 {
      align-items: center;
      border-top: 1px solid #0f4053;
      color: #fff;
      display: flex;
      flex-direction: column;
      min-height: 58px;
      padding: 9px 0; }
      @media (min-width: 960px) {
        .global-notifications .notification h4 {
          flex-direction: row;
          justify-content: space-between; } }
      .global-notifications .notification h4:first-of-type {
        border-top: 0; }
      .global-notifications .notification h4.notification {
        display: block; }
        @media (min-width: 960px) {
          .global-notifications .notification h4.notification {
            line-height: 40px; } }
        .global-notifications .notification h4.notification strong {
          font-weight: bold; }
    .global-notifications .notification span {
      box-sizing: border-box;
      background: #9aba38;
      margin-top: 18px;
      padding: 9px 18px;
      text-align: center;
      width: 100%; }
      @media (min-width: 960px) {
        .global-notifications .notification span {
          margin-top: 0;
          width: auto; } }
      .global-notifications .notification span.error {
        background: #e75345; }
      .global-notifications .notification span a {
        color: #fff; }

/*
Glossary

Please note the required javascript at the begining of the CSS bellow.

Markup:
<section class="glossary">
  <div class="glossary-x-header">
    <h1>Glossary</h1>
    <p class="glossary-x-text-lightGrey">Select a term in the list to view its definition.</p>
    <sg-insert>3.52</sg-insert>
  </div>
  <div class="l-glossary">
    <div class="glossary-x-terms">
      <article>
        <input type="radio" name="accordion" value="accordion1" id="accordion1" checked="checked">
        <label for="accordion1"><h1>Sensitivity</h1></label>
        <div>
            <p class="glossary-x-term">Sensitivity</p>
             This involves empathy, where you can see situations through other people’s eyes and feel what they feel. Sensitivity means considering the feelings and welfare of others before you act. It means that you do not always put your needs of the moment first. Sensitivity increases with maturity, wisdom, compassion, and self-control.
        </div>
      </article>
      <article>
        <input type="radio" name="accordion" value="accordion2" id="accordion2">
        <label for="accordion2"><h1>Empathy</h1></label>
        <div>
            <p class="glossary-x-term">Empathy</p>
             When you can feel what another person feels, this is empathy. It involves putting yourself in someone else’s shoes so as to see the situation from their point of view and experience the feelings they do. Empathy is the basis for intimate relationships and builds respect for the other person. People who are high in emotional intelligence have good empathy skills.
        </div>
      </article>
      <article>
        <input type="radio" name="accordion" value="accordion3" id="accordion3">
        <label for="accordion3"><h1>Polite Request</h1></label>
        <div>
            <p class="glossary-x-term">Polite Request</p>
              This is an extension of an “I” message. After stating how you feel about another person’s behavior, you put your request for a change in behavior in a clearly polite form, then ask for their agreement:
        </div>
      </article>
      <article>
        <input type="radio" name="accordion" value="accordion4" id="accordion4">
        <label for="accordion4"><h1>Polite Request 2</h1></label>
        <div>
            <p class="glossary-x-term">Polite Request</p>
             This is an extension of an “I” message. After stating how you feel about another person’s behavior, you put your request for a change in behavior in a clearly polite form, then ask for their agreement:
        </div>
      </article>
    </div>
    <div id="glossary-x-definition">
      This is an extension of an “I” message. After stating how you feel about another person’s behavior, you put your request for a change in behavior in a clearly polite form, then ask for their agreement:
    </div>
  </div>
</section>


Styleguide 3.49
*/
/*
This pattern assumes and requires the following javascript / Jquery snippet:
window.nextTerm = null;
window.muted = false;

function muteAudio() {
    $('#volume_control').find('#volumeDisplay').addClass('audioGlossary-muted');
    window.muted = true;
    if (window.glossary_term_audio) {
        window.glossary_term_audio.pause();
        window.glossary_term_audio.muted = true;
    }
}

function unmuteAudio() {
    $('#volume_control').find('#volumeDisplay').removeClass('audioGlossary-muted');
    window.muted = false;
    if (window.glossary_term_audio) {
        window.glossary_term_audio.play();
        window.glossary_term_audio.muted = false;
    }
}

function toggleMute() {
    if (window.muted) {
        unmuteAudio();
    } else {
        muteAudio();
    }
}


$('#volume_control').click(function(){
    toggleMute();
});


function playDefinitionAudio(audioUrl) {
    if (!window.muted){
        if (window.glossary_term_audio) {
            window.glossary_term_audio.pause();
            window.glossary_term_audio.src = audioUrl;
        }
        else {
            window.glossary_term_audio = new Audio(audioUrl);
        }
        window.glossary_term_audio.play();
    } else if (window.glossary_term_audio) {
        window.glossary_term_audio.src = audioUrl;
    }
}



$(function() {

    var glossary = $('#glossary');
    showDefinition(glossary.find('article:has(input:checked)'));

    glossary.find('input').click(function(event) {
        var target = $(event.currentTarget),
            termElement = target.closest('article'),
            radio = termElement.find('input:checked'),
            audioUrl = radio.data('audioUrl');

        showDefinition(termElement);
        if (audioUrl) {
            playDefinitionAudio(audioUrl);
        }
    });

    function getDefinitionForSelectedTerm(termElement) {
        return termElement.find('.glossary-x-term').parent();
    }

    function showDefinition(termElement) {
        var definition = getDefinitionForSelectedTerm(termElement).clone(),
            displayElement = $("#glossary-x-definition");
        displayElement.html(definition);
    }

});

*/
.glossary .glossary-x-terms > article {
  padding-left: 16px;
  padding-right: 16px; }
  .glossary .glossary-x-terms > article > input {
    display: none; }
    .glossary .glossary-x-terms > article > input + label {
      color: #28ade3;
      cursor: pointer;
      display: block;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-top: 16px;
      border-right: 1px solid #D0D0D0;
      border-top: 1px solid #D0D0D0; }
      .glossary .glossary-x-terms > article > input + label h1 {
        font-size: 14px; }
    .glossary .glossary-x-terms > article > input + label + * {
      color: #696969;
      height: auto;
      opacity: 1;
      padding-left: 18px;
      transition: opacity 0.25s ease-in-out; }
  .glossary .glossary-x-terms > article > input:not(:checked) + label span {
    transform: rotate3d(0, 0, 1, -90deg);
    transition: none; }
  .glossary .glossary-x-terms > article > input:not(:checked) + label + * {
    height: 0;
    margin-bottom: 0;
    margin-top: 0;
    opacity: 0;
    overflow: hidden; }
  .glossary .glossary-x-terms > article > input:checked + label h1 {
    color: #2A5A73;
    font-weight: bold; }
  .glossary .glossary-x-terms > article > input:checked + label + * {
    padding-bottom: 18px;
    padding-right: 18px;
    border-right: 1px solid #D0D0D0; }

.glossary .glossary-x-terms .glossary-x-term {
  display: none; }

.glossary .glossary-x-terms .glossary-x-term-header {
  display: none; }

.glossary .glossary-x-terms .glossary-x-text-lightGrey {
  color: #7C7C7C; }

.glossary #glossary-x-definition {
  display: none; }

.glossary .glossary-x-header {
  padding: 20px 23px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }

@media (min-width: 650px) {
  .glossary {
    padding: 31px 70px; }
    .glossary .glossary-x-terms > article {
      padding-left: 0;
      padding-right: 0;
      width: 290px; }
      .glossary .glossary-x-terms > article > input + label + * {
        display: none; }
      .glossary .glossary-x-terms > article > input:checked + label + * {
        border: 0; }
    .glossary #glossary-x-definition {
      color: #696969;
      display: block;
      padding-left: 30px;
      padding-top: 16px;
      border-top: 1px solid #D0D0D0; }
    .glossary .glossary-x-header {
      padding-left: 0;
      margin-bottom: 0; }
    .glossary .glossary-x-term-header {
      color: black;
      display: block;
      font-size: 32px; }
    .glossary .glossary-x-term {
      display: block;
      color: black;
      font-size: 32px;
      line-height: 38px;
      font-weight: normal; } }

/*
audio glossary

Markup:
<p>
  <a id="volume_control" class="audioGlossary">
    <span class="audioGlossary-x-text">Read Aloud</span>
    <span id="volume_control" class="audioGlossary-icon audioGlossary-icon-muted"></span>
  </a>
</p>

Styleguide 3.52
*/
.audioGlossary {
  color: #000;
  text-align: center; }
  .audioGlossary:hover {
    color: #000; }
  .audioGlossary .audioGlossary-icon {
    outline: none;
    display: inline-block; }
    .audioGlossary .audioGlossary-icon, .audioGlossary .audioGlossary-icon svg {
      height: 30px;
      width: 30px; }
    .audioGlossary .audioGlossary-icon .icon-unmute {
      display: none; }
    .audioGlossary .audioGlossary-icon.audioGlossary-muted .icon-unmute {
      display: block; }
    .audioGlossary .audioGlossary-icon.audioGlossary-muted .icon-mute {
      display: none; }
  .audioGlossary span {
    display: block; }

/*
Guided Question

Markup:
<div class="guided-question" id="course_container">
  <p>Let's consider the following:</p>
  <h1 id="question">What do children need when they make a transition from one parent's home to the other's?</h1><p>They need quiet time and gentle support from each parent. They need time to decompress from the strong feelings of divided loyalties. Children may shut out one or both parents during these times as they struggle with feelings of loyalty to the absent parent. If conflict between parents occurs, it can take the children and parents up to 72 hours to get the adrenaline out of their systems. </p>
  <section class="controls">
    <a id="next_button" class="button" href="#">Next Question</a>
    <aside style="">
      <p class="h2">More information:</p>
      <ul id="footnotes"><li><a href="#" class="footnote" id="5">5</a></li></ul>
    </aside>
  </section>
</div>

Styleguide 3.11
*/
.guided-question {
  padding: 0 18px 18px 18px; }
  .guided-question h1 {
    font-size: 24px;
    margin-bottom: 18px; }
    @media (min-width: 960px) {
      .guided-question h1 {
        font-size: 30px; } }
    .guided-question h1 a {
      color: #136d92; }
  .guided-question h2 {
    font-size: 14px;
    margin-bottom: 0; }
    @media (min-width: 960px) {
      .guided-question h2 {
        font-size: 24px;
        margin-bottom: 9px; } }
  .guided-question p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: .5em;
    display: inline; }
  .guided-question .controls {
    align-items: center;
    display: flex;
    flex-direction: column; }
    @media (min-width: 960px) {
      .guided-question .controls {
        flex-direction: row;
        padding-top: 18px; } }
  .guided-question .button {
    margin: 18px 0;
    width: 100%; }
    @media (min-width: 960px) {
      .guided-question .button {
        font-size: 24px;
        margin: 0 18px 0 0;
        width: auto; } }
  .guided-question aside p {
    float: left;
    display: inline-block;
    margin: 9px;
    line-height: 1em;
    font-size: 14px; }
    @media (min-width: 960px) {
      .guided-question aside p {
        font-size: 18px; } }
  .guided-question aside ul {
    float: left; }
    .guided-question aside ul li {
      list-style: none;
      float: left;
      margin-right: 9px; }
      .guided-question aside ul li a {
        display: block;
        background: #9aba38;
        border-radius: 5px;
        padding: 9px;
        color: #fff;
        font-weight: normal;
        line-height: 1em; }
        @media (min-width: 960px) {
          .guided-question aside ul li a {
            font-size: 18px; } }
        .guided-question aside ul li a:hover {
          background: #7a932c; }

.question-footnotes {
  display: block;
  background: #ebebeb;
  border-radius: 3px;
  padding: 32px;
  color: #696969;
  margin-top: 17px; }
  .question-footnotes .question-footnotes-title {
    font-weight: bold; }
  .question-footnotes p {
    margin-bottom: 17px;
    display: block; }

/*
High Conflict

Markup:
<div class="highconflict">
  <img src="http://placehold.it/56x56" width="56" height="56">
  <div class="highconflict-x-text">
    <h2 id="heading-highconflict">Looking for a High-Conflict Class?</h2>
    <p><a href="#" aria-labelledby="heading-highconflict">Click for more information</a></p>
  </div>
</div>

Styleguide 3.55
*/
.highconflict {
  align-items: center;
  display: flex; }
  .highconflict a,
  .highconflict h2,
  .highconflict p {
    color: #781012;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px; }
  .highconflict a {
    text-decoration: underline; }
  .highconflict .highconflict-x-text {
    margin-left: 18px; }

/*
Link List

Markup:
<ul class="linklist">
  <li><a href="#">CDE's Children in Between Online 30-day Account</a></li>
  <li><a href="#">Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing Elit</a></li>
  <li><a href="#">Aliquam diam mauris: facilisis nec sollicitudin vitae porta laoreet arcu</a></li>
</ul>

Styleguide 3.57
*/
.linklist a {
  font-size: 18px;
  font-weight: bold; }

.linklist li:not(:last-child) {
  margin-bottom: 18px; }

/*
Loader

Markup:
<!-- Position relative override just for the pattern library, position fixed by default -->
<div id="item-loading" class="loader" style="position: relative;">
    <p>Loading</p>
</div>

Styleguide 3.12
*/
@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loader {
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  color: #28ade3;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; }
  .loader i {
    display: block;
    margin-bottom: 18px; }
  .loader p {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: center;
    position: relative;
    text-align: center;
    text-transform: uppercase; }
    .loader p::before {
      animation: loader .8s infinite ease-in-out;
      border: 4.5px solid #28ade3;
      border-radius: 50%;
      border-right-color: transparent;
      content: '';
      display: block;
      height: 28px;
      margin: 0 0 18px 0;
      width: 28px; }

/*
Logo

Markup:
<a href="/" class="logo"><img src="/images/logo-cib.svg" alt="Children In Between Logo" width="256" height="45"></a>

Styleguide 3.29
*/
.logo {
  display: block; }
  .logo img {
    display: block; }

/*
Management Table

Markup:
<section class="management-table">
  <table>
    <thead>
      <tr>
        <th scope="col">Extra Credit Category</th>
        <th scope="col">Value</th>
        <th scope="col"># Complete</th>
        <th scope="col">Total</th>
        <th scope="col">Applied</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">Posting in the Forum (Maximum 50%)</th>
        <td>2%</td>
        <td>0 of 25</td>
        <td>0%</td>
        <td>-</td>
      </tr>
      <tr>
        <th scope="row">Skills Practices (Maximum 50%)</th>
        <td>2%</td>
        <td>25 of 25</td>
        <td>50%</td>
        <td>-</td>
      </tr>
      <tr>
        <th scope="row">Skills Practices (Maximum 50%)</th>
        <td>2%</td>
        <td>25 of 25</td>
        <td>50%</td>
        <td>-</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th scope="row">Totals</th>
        <td></td>
        <td>25</td>
        <td>50%</td>
        <td>0%</td>
      </tr>
    </tfoot>
  </table>
</section>

Styleguide 3.13
*/
.management-table {
  overflow-x: auto;
  margin: 0 18px 18px; }
  .management-table table {
    border-collapse: collapse;
    font-size: 18px;
    width: 100%; }
  .management-table td,
  .management-table th {
    padding: 18px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap; }
    @media (min-width: 960px) {
      .management-table td,
      .management-table th {
        white-space: normal; } }
    .management-table td:last-child,
    .management-table th:last-child {
      text-align: right; }
    .management-table td:first-child,
    .management-table th:first-child {
      text-align: left; }
  .management-table thead th {
    background: #0a3b4e;
    color: #fff;
    font-size: 16px;
    padding-bottom: 9px;
    padding-top: 9px; }
  .management-table thead th:first-child {
    border-top-left-radius: 5px; }
  .management-table thead th:last-child {
    border-top-right-radius: 5px; }
  .management-table tbody td,
  .management-table tbody th {
    box-shadow: #ddd 0 -1px 0 0 inset; }
    .management-table tbody td:first-child,
    .management-table tbody th:first-child {
      box-shadow: #ddd 1px -1px 0 0 inset; }
    .management-table tbody td:last-child,
    .management-table tbody th:last-child {
      box-shadow: #ddd -1px -1px 0 0 inset; }
    .management-table tbody td:only-child,
    .management-table tbody th:only-child {
      box-shadow: #ddd 0 0 0 1px inset; }
  .management-table tbody tr:nth-child(even) td,
  .management-table tbody tr:nth-child(even) th {
    background-color: #F2F2F2; }
  .management-table tbody tr:last-of-type td:first-child,
  .management-table tbody tr:last-of-type th:first-child {
    border-bottom-left-radius: 5px; }
  .management-table tbody tr:last-of-type td:last-child,
  .management-table tbody tr:last-of-type th:last-child {
    border-bottom-right-radius: 5px; }
  .management-table tfoot {
    font-weight: bold; }

/*
Menu

This is fragmented, these items should become their own modules.

Default - Used in main navigation
.menu-page - Used inline on pages

Markup:
<ul class="menu {$modifiers}">
  <li class="menu-item"><a class="course" href="/course">Course</a></li>
  <li class="menu-item"><a href="/resources/">Resources</a></li>
  <li class="menu-item menu-dropdown" tabindex="1"><a id="downloads" href="">Downloads</a>
    <ul>
      <li><a href="/lesson/handbooks/what-about-children">What About the Children? 8th Ed</a></li>
      <li><a href="/lesson/handbooks/children-in-between">Children in Between 6th Ed.</a></li>
    </ul>
  </li>
  <li class="menu-item"><a class="glossary" href="/reference/glossary">Glossary</a></li>
</ul>

Styleguide 3.28
*/
.menu {
  max-height: 0;
  overflow: hidden;
  transition: all .25s ease-out;
  width: 100%; }
  .menu:first-of-type {
    box-shadow: #155a76 0 5px 0 0 inset; }
  @media (min-width: 950px) {
    .menu {
      display: flex;
      max-height: inherit;
      overflow: visible;
      width: auto; }
      .menu:first-of-type {
        box-shadow: none;
        padding-top: 0; } }
  @media (max-width: 950px) {
    .menu.is-active {
      max-height: 1000px;
      padding-top: 5px; } }

.menu-page:first-of-type {
  box-shadow: #F2F2F2 0 1px 0 0 inset; }

@media (min-width: 950px) {
  .menu-page {
    align-items: stretch;
    box-shadow: none;
    flex-direction: column;
    width: 100%; } }

/*
Menu Item

Default - Used in main navigation
.menu-page-page - Used inline on pages

Markup:
<div class="menu-item {$modifiers}"><a class="course" href="/course">Course</a></div>

Styleguide 3.28.2
*/
.menu-item {
  border-bottom: 1px solid #F2F2F2;
  padding: 0 18px; }
  @media (min-width: 950px) {
    .menu-item {
      border-bottom: 0;
      margin: 0 18px;
      padding: 13.5px 0;
      text-transform: uppercase; }
      .menu-item.has-limit > a {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; } }
  .menu-item a,
  .menu-item > span {
    display: block;
    color: #28ade3;
    cursor: pointer;
    font-weight: normal;
    line-height: 1;
    padding: 18px 0; }
    @media (min-width: 950px) {
      .menu-item a,
      .menu-item > span {
        color: #000;
        padding: 0; }
        .menu-item a:hover,
        .menu-item > span:hover {
          color: rgba(0, 0, 0, 0.5); } }

@media (min-width: 950px) {
  .menu-item-page {
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
    text-transform: none;
    width: 100%; }
    .menu-item-page:nth-child(odd) {
      background-color: #F2F2F2; } }

.menu-item-page.active a {
  font-weight: bold; }

/*
Menu Dropdown

Markup:
<div class="menu-item menu-dropdown" tabindex="1"><a id="downloads" href="">Downloads</a>
  <ul>
    <li><a href="/lesson/handbooks/what-about-children">What About the Children? 8th Ed</a></li>
    <li><a href="/lesson/handbooks/children-in-between">Children in Between 6th Ed.</a></li>
  </ul>
</div>

Styleguide 3.28.3
*/
.menu-dropdown {
  outline: none;
  position: relative; }
  .menu-dropdown a {
    padding-left: 1.5em;
    position: relative; }
    @media (min-width: 950px) {
      .menu-dropdown a {
        padding-left: 0; } }
  .menu-dropdown > a {
    pointer-events: none; }
    @media (min-width: 950px) {
      .menu-dropdown > a {
        pointer-events: auto;
        padding-right: 1.5em; } }
    .menu-dropdown > a::before {
      background: url("images/menu-indicator.svg") center center no-repeat;
      background-size: 10px 15px;
      content: '';
      display: block;
      height: 1em;
      left: 0;
      position: absolute;
      top: 18px;
      transition: all .1s ease-out;
      width: 1em; }
      @media (min-width: 950px) {
        .menu-dropdown > a::before {
          color: #28ade3;
          left: auto;
          right: 0;
          top: -2px;
          transform: rotate(90deg); } }
  .menu-dropdown ul {
    max-height: 0;
    overflow: hidden;
    transition: all .25s ease-out; }
    @media (min-width: 950px) {
      .menu-dropdown ul {
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        min-width: 200px;
        position: absolute;
        right: 0;
        text-align: right;
        top: 100%;
        transition: none;
        white-space: nowrap;
        z-index: 1000; } }
    .menu-dropdown ul a {
      color: #155a76; }
      @media (min-width: 950px) {
        .menu-dropdown ul a {
          padding: 18px; } }
    .menu-dropdown ul a:hover {
      opacity: .5; }
      @media (min-width: 950px) {
        .menu-dropdown ul a:hover {
          background: rgba(0, 0, 0, 0.05);
          opacity: 1; } }
  @media (min-width: 950px) {
    .menu-dropdown li {
      border-bottom: 1px solid #d9d9d9;
      padding: 0;
      text-transform: none; } }
  .menu-dropdown:hover ul, .menu-dropdown:focus ul {
    max-height: 1000px; }
  .menu-dropdown:hover a::before, .menu-dropdown:focus a::before {
    transform: rotate(90deg); }

/*
Menu Item

Markup:
<li class="menuitem"><a href="">Menu Item</a><li>

Styleguide 3.45
*/
.menuitem {
  border-bottom: 1px solid #f6f3ee;
  line-height: 1;
  outline: none;
  white-space: nowrap; }
  .menuitem > a,
  .menuitem button {
    appearance: none;
    background: transparent;
    border: 0;
    box-sizing: border-box;
    color: #28ade3;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: normal;
    outline: none;
    padding: 18px;
    text-align: left;
    width: 100%; }
  @media (min-width: 860px) {
    .menuitem {
      border-bottom: 0; }
      .menuitem > a,
      .menuitem button {
        padding: 0; } }

.menuitem-dropdown button {
  padding-left: 35px;
  position: relative; }
  .menuitem-dropdown button::before {
    background: url("images/menuitem-dropdown.svg") center center no-repeat;
    content: '';
    display: block;
    height: 8px;
    left: 18px;
    margin-right: 10px;
    position: absolute;
    top: calc(50% - 4px);
    transition: transform 0.25s ease-in-out;
    width: 8px;
    will-change: transform; }

.menuitem-dropdown ul {
  padding-bottom: 9px;
  white-space: normal; }

.menuitem-dropdown li a {
  color: #155a76;
  display: block;
  padding: 9px 18px 9px 36px; }

.menuitem-dropdown [aria-hidden="true"] {
  display: none; }

.menuitem-dropdown [aria-hidden="false"] {
  display: block; }

.menuitem-dropdown.menuitem-is-active button::before {
  transform: rotate(90deg); }

@media (min-width: 860px) {
  .menuitem-dropdown {
    position: relative; }
    .menuitem-dropdown button {
      padding-left: 0;
      padding-right: 26px; }
      .menuitem-dropdown button::before {
        left: auto;
        right: 0;
        transform: rotate(90deg); }
    .menuitem-dropdown ul {
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
      padding: 0;
      position: absolute;
      right: 0;
      top: calc(100% + 9px);
      width: 143px; }
    .menuitem-dropdown li a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      color: #28ade3;
      padding: 9px; } }

.menuitem-secondary {
  border-bottom: 0;
  border-right: 1px solid #f6f3ee; }
  .menuitem-secondary > a {
    font-size: 14px;
    text-align: center; }
  @media (min-width: 860px) {
    .menuitem-secondary > a {
      border-right: 0;
      color: #155a76;
      padding: 0; } }

@media (min-width: 860px) {
  .menuitem-marketing {
    border: 0; }
    .menuitem-marketing > a {
      border: 1px solid #28ade3;
      border-radius: 8px;
      padding: 9px 18px; } }

/*
Navigation

Markup:
<nav class="navigation" id="navigation">
  <div class="navigation-x-container l-containerhorizontal">
    <header>
      <a href="/" class="navigation-x-logo"><img src="/images/logo-cib.svg" alt="Children In Between Logo" width="256" height="45"></a>
      <button class="navigation-x-toggle"><span class="visually-hidden"> Menu</span></button>
    </header>
    <div class="navigation-x-message">
      <sg-insert>3.55</sg-insert>
    </div>
    <div class="navigation-x-menu">
      <ul class="l-menu l-menu-secondary">
        <li class="menuitem menuitem-secondary"><a href="/set-language/es" class="language"><span>En Español?</span></a></li>
        <li class="menuitem menuitem-secondary"><a href="/contact">Contact</a></li>
      </ul>
      <ul class="l-menu">
        <li class="menuitem menuitem-marketing"><a href="/shopping/purchase/">Sign Up</a></li>
        <li class="menuitem menuitem-marketing"><a href="/user/login">Login</a></li>
      </ul>
    </div>
  </div>
</nav>

Styleguide 3.3
*/
.navigation {
  background: #fff;
  border-bottom: 5px solid #28ade3;
  position: relative; }
  .navigation header {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 18px;
    width: 100%; }
  .navigation .navigation-x-logo {
    display: flex;
    align-items: center;
    max-width: 70%; }
  .navigation .language-toggle {
    display: flex;
    padding: 17px 13px;
    align-items: left;
    /* Transition*/ }
    .navigation .language-toggle label {
      padding: 0 5px; }
    .navigation .language-toggle .en-language, .navigation .language-toggle .es-language {
      padding-top: 3px; }
    .navigation .language-toggle .toggle {
      position: relative;
      display: flex;
      display: block;
      width: 60px;
      height: 20px;
      padding: 0 3px;
      border-radius: 10px;
      cursor: pointer; }
    .navigation .language-toggle .toggle-input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0; }
    .navigation .language-toggle .toggle-label {
      position: relative;
      display: block;
      height: inherit;
      font-size: 12px;
      background: #26ade3;
      border-radius: inherit;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 3px rgba(0, 0, 0, 0.15); }
    .navigation .language-toggle .toggle-label:before,
    .navigation .language-toggle .toggle-label:after {
      position: absolute;
      top: 50%;
      color: black;
      margin-top: -.5em;
      line-height: 1; }
    .navigation .language-toggle .toggle-label:before {
      content: attr(data-off);
      right: 11px;
      color: #fff;
      text-shadow: 0 1px rgba(255, 255, 255, 0.5); }
    .navigation .language-toggle .toggle-label:after {
      content: attr(data-on);
      left: 11px;
      color: #fff;
      text-shadow: 0 1px rgba(0, 0, 0, 0.2);
      opacity: 0; }
    .navigation .language-toggle .toggle-input:checked ~ .toggle-label {
      background: #26ade3; }
    .navigation .language-toggle .toggle-input:checked ~ .toggle-label:before {
      opacity: 0; }
    .navigation .language-toggle .toggle-input:checked ~ .toggle-label:after {
      opacity: 1; }
    .navigation .language-toggle .toggle-handle {
      position: absolute;
      top: 1.5px;
      left: 4px;
      width: 18px;
      height: 18px;
      background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
      border-radius: 50%; }
    .navigation .language-toggle .toggle-handle:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -6px 0 0 -6px;
      width: 16px;
      height: 16px; }
    .navigation .language-toggle .toggle-input:checked ~ .toggle-handle {
      left: 38px;
      box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2); }
    .navigation .language-toggle .toggle-label,
    .navigation .language-toggle .toggle-handle {
      transition: All 0.3s ease;
      -webkit-transition: All 0.3s ease;
      -moz-transition: All 0.3s ease;
      -o-transition: All 0.3s ease; }
  .navigation #locale-pin-img, .navigation #locale-pin-label {
    cursor: pointer; }
  .navigation .navigation-x-container {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .navigation .navigation-x-container .locale-pin {
      display: flex;
      align-items: center;
      padding: 17px 12px; }
  .navigation .navigation-x-toggle {
    appearance: none;
    background: url("images/navigation-toggle.svg") center center no-repeat;
    border: 0;
    color: transparent;
    cursor: pointer;
    height: 30px;
    outline: none;
    overflow: hidden;
    transition: transform 0.25s ease-in-out;
    user-select: none;
    width: 30px;
    will-change: transform; }
  .navigation .navigation-x-menu {
    background-color: #fff;
    border-bottom: 5px solid #28ade3;
    border-top: 5px solid #28ade3;
    display: flex;
    flex-direction: column;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.25s ease-in-out;
    width: 100%;
    will-change: transform;
    z-index: 100; }
    .navigation .navigation-x-menu > :first-child {
      order: 2; }
    .navigation .navigation-x-menu > :last-child {
      order: 1; }
  .navigation .navigation-x-message {
    display: none; }
  .navigation.navigation-is-active .navigation-x-menu {
    pointer-events: auto;
    transform: none; }
  .navigation.navigation-is-active .navigation-x-toggle {
    transform: rotate(-90deg); }
  @media (min-width: 860px) {
    .navigation {
      align-items: center;
      display: flex; }
      .navigation header {
        width: auto; }
      .navigation .navigation-x-container .locale-pin {
        padding-left: 0; }
      .navigation .navigation-x-logo {
        max-width: 405px; }
      .navigation .navigation-x-message {
        display: block; }
      .navigation .navigation-x-toggle {
        display: none; }
      .navigation .navigation-x-menu {
        border-bottom: 0;
        border-top: 0;
        padding: 18px 18px 18px 0;
        pointer-events: auto;
        position: relative;
        transform: none;
        width: auto;
        will-change: none; }
        .navigation .navigation-x-menu > :first-child:not(:only-child) {
          margin-bottom: 18px; }
        .navigation .navigation-x-menu > :first-child {
          order: 1; }
        .navigation .navigation-x-menu > :last-child {
          order: 2; } }

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 999;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  /* Could be more or less, depending on screen size */
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative; }

/* The Close Button */
.close {
  color: #aaa;
  position: absolute;
  right: 4px;
  top: 0;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: right;
  transition: all 0.3s; }

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

/* Disable scrolling */
body.modal-open {
  overflow: hidden; }

input#confirm_filing_county_button {
  width: 100%;
  font-size: 18px; }

/*
Navigation Page

Markup:
<nav class="navigation navigation-page glossary-navigation" id="page-navigation-pattern">
  <header>
    <h2 class="navigation-title">Glossary Menu</h2>
    <button class="menu-toggle is-inactive" id="menu-toggle"><span class="visually-hidden"> Menu</span></button>
  </header>
  <ul class="menu menu-page">
    <li class="menu-item menu-item-page"><a href="">Active Listening</a href=""></li>
    <li class="menu-item menu-item-page active"><a href="">Empathy</a href=""></li>
    <li class="menu-item menu-item-page"><a href="">I Message</a href=""></li>
    <li class="menu-item menu-item-page"><a href="">Impulse Control</a href=""></li>
  </ul>
</nav>

Styleguide 3.3.1
*/
.navigation-page {
  box-shadow: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10; }
  @media (min-width: 950px) {
    .navigation-page {
      align-items: flex-start;
      flex-wrap: nowrap;
      height: auto;
      left: auto;
      position: relative;
      top: auto; }
      .navigation-page header {
        display: none; } }
  .navigation-page .navigation-title {
    font-size: 18px;
    line-height: 1em;
    margin: 18px;
    text-transform: uppercase; }

/*
Order Summary

Summary to be placed inside of form module

Markup:
<table class="ordersummary">
  <tfoot>
    <tr>
      <th scope="row">Your Total:</th>
      <td>$59.95</td>
    </tr>
  <tfoot>
  <thead class="visuallyhidden">
    <tr>
      <th scope="col">Item</th>
      <th scope="col">Price</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">30-Day Access</th>
      <td>$49.95</td>
    </tr>
    <tr>
      <th scope="row">Workbook</th>
      <td>$20.00</td>
    </tr>
    <tr>
      <th scope="row">Discount</th>
      <td>-$4.00</td>
    </tr>
    <tr>
      <th scope="row">Sales Tax</th>
      <td>$2.00</td>
    </tr>
    <tr>
      <th scope="row">Data Management Fee</th>
      <td>$2.00</td>
    </tr>
  </tbody>
</table>

Styleguide 3.53
*/
.ordersummary {
  table-layout: fixed;
  width: 100%; }
  .ordersummary tbody th,
  .ordersummary tbody td {
    color: #696969;
    font-size: 14px;
    line-height: 22px; }
  .ordersummary td {
    text-align: right; }
  .ordersummary tfoot td,
  .ordersummary tfoot th {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    color: #000;
    font-size: 24px;
    padding-top: 18px; }
  .ordersummary tfoot td {
    font-weight: bold; }
  .ordersummary th {
    text-align: left; }

/*
Overlay

Markup:
<div class="overlay">
  <a>close</a>
  <h1>Change Email for Co-Parent</h1>
  <p>Enter a new email address to associate with this gift account. An invitation email will be sent to the user at the new email address.</p>
  <hr>
  <a class="button" href="#">Yes, Cancel Gift Invite</a>
</div>

Styleguide 3.48
*/
.overlay {
  display: flex;
  flex-direction: column; }
  .overlay:not(form) {
    margin-left: 18px;
    margin-right: 18px; }
  .overlay a:first-of-type {
    align-self: flex-end;
    color: #28ade3;
    margin-bottom: 23.4px;
    margin-left: 0;
    margin-right: 0;
    text-decoration: none; }
  .overlay h1 {
    font-size: 18px;
    line-height: 22px; }
  .overlay hr {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 20px; }
  .overlay p {
    font-size: 12px;
    line-height: 14px; }

/*
Overlay (with a form element).

Markup:
<div class="overlay">
<a>close</a>
  <h1>Change Email for Co-Parent</h1>
  <p>Enter a new email address to associate with this gift account. An invitation email will be sent to the user at the new email address.</p>
  <hr>
  <form class="form form-inline">
    <div class="form-row ">
      <label for="id_name">New Email:</label>
      <div class="value">
        <input type="text" placeholder="eg. john@example.com" name="name" id="id_name">
        <p>This is helper text for this input.</p>
      </div>
    </div>
    <button type="submit" class="button button-width-full" href="#">Change Email and Send Invite Email</a>
  </form>
</div>

Styleguide 3.48.1
*/
/*
Page Title

Default - Standard page titles
.page-title-large - A larger version
.page-title-centered - When you want the text centered

Markup:
<header class="page-title {$modifiers}">
  <h1>Sign-up for CDE's Children in Between</h1>
  <h2><span>$39.95</span> for 30-days access</h2>
  <p>Sign-up is simple and takes just a few minutes.</p>
</header>

Styleguide 3.4
*/
.page-title {
  box-sizing: border-box;
  margin-bottom: 18px;
  padding: 36px 18px 18px;
  position: relative; }
  .page-title.page-title-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .page-title h1 {
    font-size: 30px;
    line-height: 1em; }
  .page-title h2 {
    color: #ccc;
    font-size: 24px;
    line-height: 1em;
    margin-top: 9px; }
    .page-title h2 span {
      color: #28ade3; }
  .page-title p {
    color: #696969;
    font-size: 18px;
    line-height: 1.2em;
    margin-top: 9px; }

.audio {
  color: #000;
  text-align: center;
  font-size: 14px; }
  .audio:hover {
    color: #000; }
  .audio .audio-x-icon {
    outline: none;
    display: inline-block; }
    .audio .audio-x-icon, .audio .audio-x-icon svg {
      height: 30px;
      width: 30px; }
    .audio .audio-x-icon .icon-unmute {
      display: none; }
  .audio.muted .icon-unmute {
    display: block; }
  .audio.muted .icon-mute {
    display: none; }
  .audio .audio-x-text {
    display: block;
    line-height: 14px; }

.page-title-margin-bottom-none {
  margin-bottom: 0; }

.page-title-margin-bottom {
  margin-bottom: 18px; }

.page-title-margin-top {
  margin-top: 18px; }

.page-title-padding-top-none {
  padding-top: 0; }

.page-title-small {
  border-bottom: 1px solid #ccc;
  padding: 0 0 18px; }
  .page-title-small h1 {
    font-size: 18px; }
  .page-title-small p {
    font-size: 14px; }

.page-title-small-x-left-padding {
  padding-left: 18px; }

@media (min-width: 950px) {
  .page-title-large {
    padding-top: 36px; } }

.page-title-large h1 {
  font-size: 36px; }
  @media (min-width: 950px) {
    .page-title-large h1 {
      font-size: 48px;
      margin-bottom: 18px; } }

.page-title-large h2 {
  font-size: 36px; }
  @media (min-width: 950px) {
    .page-title-large h2 {
      font-size: 30px; }
      .page-title-large h2 span {
        font-size: 48px; } }

.page-title-large p {
  font-size: 18px; }

/*
Page Title Course

For use above videos. TODO: This should be its own module.

Markup:
<header class="page-title page-title-course">
  <h1>Chapter 1: Carrying Messages</h1>
  <p class="audio muted" id="volume_control"><a href="#">Read Aloud</a></p>
</header>

Styleguide 3.4.1
*/
.page-title-course {
  border-bottom: 1px solid #ccc;
  padding-right: 58px;
  padding-top: 18px; }
  @media (min-width: 960px) {
    .page-title-course {
      padding: 36px 0 18px;
      margin-left: 18px;
      margin-right: 18px; } }
  .page-title-course h1 {
    font-size: 24px;
    line-height: 1.2em; }
    @media (min-width: 960px) {
      .page-title-course h1 {
        font-size: 30px;
        line-height: 1em; } }

/*
Page Title Controls

A version that has a button. TODO: This should be its own module.

Markup:
<header class="page-title page-title-controls">
  <section>
    <h1>Tiffany Prado</h1>
    <p>tifa.tifa@gmail.com</p>
  </section>
  <section>
    <a class="button" href="/manage/36/license/36/edit/3324/">Edit User</a>
  </section>
</header>

Styleguide 3.4.2
*/
.page-title-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 960px) {
    .page-title-controls {
      align-items: center;
      flex-direction: row; } }
  .page-title-controls section {
    margin-bottom: 18px; }
    @media (min-width: 960px) {
      .page-title-controls section {
        margin-bottom: 0; } }

.page-title-centered {
  text-align: center; }

/*
Person

Markup:
<article class="person">
  <section class="image">
    <img src="/images/jamesbillings.jpg" alt="Dr. James Billings, LMFT" width="290">
  </section>
  <section class="text">
    <h2>Dr. James Billings, LMFT</h2>
    <h3>Short Introduction</h3>
    <p>Dr. Billings a licensed Marriage and Family Therapist (LMFT) in the state of California has specialized working with couples and families for the past ten years. He has developed and implemented a county wide divorce parenting programs using both the Children in Between and After the Storm programs, for the family courts in a large Southern California county.</p>
    <h3>Additional Information</h3>
    <p>Dr. Billings has both a Masters Degree and Ph.D. in Marriage and Family Therapy. He is dedicated to helping individual, couples, families create healthier and happier relationships. Dr. Billings is certified in a number of parenting, co-parenting, and marriage enhancement programs (PRICE Parenting, PREP, Couples Communication Inc., Children in Between, and After the Storm). Dr. Billings is also a co-author of the Leaders Guide for the After the Storm program. He developed a divorce parenting program by combining the Children in Between and After the Storm which was presented across a larger county in Southern California. Dr. Billings’s clinical interests are in parenting, divorce parenting, and grief and loss with children. Dr. Billings currently works as a Clinical Director of a non-profit counseling agency that specializes in working with families in distress. He also works part time as an Assistant Professor for a graduate program training Masters and Ph.D. level students for a career in Marriage and Family Therapy.</p>
    <h3>Contact Information:</h3>
    <p>If you are interested in consulting with Dr. Billings please contact him by email at Billingsconsulting@verizon.net with a brief description of your situation and specific questions or concerns that you have. When e-mailing, please include your name, telephone number, and the best time that you may be reached. His consultation fees are $25 for 20 minutes, $35 for 30 minutes and $50 for 45 minutes. Payments from bank accounts, credit or debit cards may be made through the secured site of Paypal (https://www.paypal.com)</p>
  </section>
</article>

Styleguide 3.15
*/
.person {
  display: flex;
  flex-direction: column; }
  @media (min-width: 650px) {
    .person {
      border-bottom: 1px solid #F2F2F2;
      flex-direction: row;
      margin-bottom: 18px; }
      .person:last-child {
        border-bottom: 0;
        margin-bottom: 0; } }
  .person img {
    display: block;
    height: auto;
    width: 100%; }
  .person .image {
    margin-bottom: 18px; }
    @media (min-width: 650px) {
      .person .image {
        flex: 1;
        margin-right: 18px; } }
  @media (min-width: 650px) {
    .person .text {
      flex: 4; } }

/*
Progress Bar

Markup:
<ul class="progressbar">
  <sg-insert>3.31-2</sg-insert>
  <sg-insert>3.31-2</sg-insert>
  <sg-insert>3.31-1</sg-insert>
  <sg-insert>3.31-1</sg-insert>
</ul>

Styleguide 3.30
*/
.progressbar {
  display: flex;
  padding-top: 22px;
  position: relative; }
  .progressbar::after {
    background-color: #F2F2F2;
    border-radius: 13px;
    content: '';
    display: block;
    height: 13px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10; }
  .progressbar li {
    list-style: none; }

/*
Progress Bar Item

The SC5 demo doesn't quite work here, as first-child comes into play. See 3.30 instead.

Default - Default state
.progressbaritem-is-active - When it's been active/complete

Markup:
<li class="progressbaritem {$modifiers}" style="width:25%">Step</li>

Styleguide 3.31
*/
.progressbaritem {
  color: #696969;
  position: relative;
  z-index: 50; }
  .progressbaritem::after {
    background-color: #F2F2F2;
    border-radius: 20px;
    content: '';
    display: block;
    height: 20px;
    left: -3px;
    position: absolute;
    top: -25.5px;
    width: 20px; }
  .progressbaritem.progressbaritem-is-active::after {
    background-color: #28ade3; }
  .progressbaritem.progressbaritem-is-active:not(:first-child)::before {
    background-color: #28ade3;
    content: '';
    display: block;
    height: 13px;
    position: absolute;
    right: 100%;
    top: -22px;
    width: 100%; }

/*
Quiz

Markup:
<div class="quiz guided-question">
    <h2>Question 1 of 11</h2>
    <h1>Why does conflict over money easily occur after divorce or separation?</h1>
    <form action="#">
        <ol class="answer-list" id="quiz"><li>
    <label for="0">
        <input id="0" type="radio" name="quiz">
        <p>Money usually becomes tighter for both parents.</p>
        <div class="fake-radio"></div>
    </label>
</li><li>
    <label for="1">
        <input id="1" type="radio" name="quiz">
        <p>Many parents who pay support resent having no say in how the money is spent.</p>
        <div class="fake-radio"></div>
    </label>
</li><li>
    <label for="2">
        <input id="2" type="radio" name="quiz">
        <p>The person receiving support often truly believes it is not enough to cover expenses.</p>
        <div class="fake-radio"></div>
    </label>
</li><li>
    <label for="3">
        <input id="3" type="radio" name="quiz">
        <p>The person paying support truly believes they are paying too much, especially if they have other financial obligations.</p>
        <div class="fake-radio selected"></div>
    </label>
</li><li>
    <label for="4">
        <input id="4" type="radio" name="quiz">
        <p>All of the above.</p>
        <div class="fake-radio"></div>
    </label>
</li></ol>
        <section class="controls">
            <div class="feedback" id="check_answer"><h2 class="incorrect">Incorrect.</h2><p>This is true, but they are just looking at it from their own point of view. Other answers are also correct.</p></div>
            <input type="submit" id="submit" class="button next" value="Next">
        </section>
    </form>
    <div class="score" id="correct_incorrect"><p>Correct: 0 / Incorrect: 1</p></div>
</div>

Styleguide 3.16
*/
.quiz form {
  display: flex;
  flex-direction: column; }

.quiz .controls {
  align-items: stretch;
  display: flex;
  flex-direction: column; }
  @media (min-width: 960px) {
    .quiz .controls {
      flex-direction: row;
      justify-content: space-between; } }

.quiz .feedback h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 0; }
  .quiz .feedback h2.incorrect {
    color: #e75345; }
  .quiz .feedback h2.correct {
    color: #9aba38; }

.quiz .feedback p {
  margin-bottom: 0; }

.quiz .answer-list {
  font-size: 14px;
  margin-left: 45px; }
  @media (min-width: 960px) {
    .quiz .answer-list {
      font-size: 24px; } }
  .quiz .answer-list li {
    list-style: upper-alpha inside;
    margin-bottom: 9px;
    min-height: 32px;
    padding-top: .25em;
    position: relative; }
    @media (min-width: 960px) {
      .quiz .answer-list li {
        padding-top: 0; } }
    .quiz .answer-list li [type="checkbox"], .quiz .answer-list li [type="radio"] {
      left: -9999px;
      position: absolute; }
    .quiz .answer-list li label {
      cursor: pointer; }
    .quiz .answer-list li p {
      font-size: 14px;
      margin-bottom: 0; }
      @media (min-width: 960px) {
        .quiz .answer-list li p {
          font-size: 24px; } }
    .quiz .answer-list li .fake-radio {
      background: url("images/quiz-unselected.png") center center no-repeat;
      background-size: contain;
      width: 30px;
      height: 32px;
      display: block;
      position: absolute;
      top: 0;
      left: -45px; }
      @media (min-width: 960px) {
        .quiz .answer-list li .fake-radio {
          top: 3px; } }
      .quiz .answer-list li .fake-radio.selected {
        background: url("images/quiz-selected.png") center center no-repeat; }

.quiz .button {
  font-size: 18px;
  margin-right: 0;
  margin-bottom: 0; }
  .quiz .button#submit {
    background-color: #28ade3; }
  .quiz .button.next {
    align-self: flex-end; }
  .quiz .button:hover {
    background: #198fbf; }

.quiz .score {
  border-top: 1px solid #cccccc;
  margin-top: 18px;
  padding: 18px 0; }
  .quiz .score p {
    font-size: 14px; }
    @media (min-width: 960px) {
      .quiz .score p {
        font-size: 18px;
        text-align: right; } }

/*
Receipt

Markup:
<section class="receipt">
  <div>
    <h1>Account Information</h1>
    <p><span>Username: jaasum</p>
    <p><span>Expiration Date:</span> 12/12/18.</p>
  </div>
  <a class="receipt-x-print" href="javascript:if(window.print)window.print()">Print this page for your records</a>
  <a class="button" href="#">Go to course</a>
</section>

Styleguide 3.32
*/
.receipt {
  margin-bottom: 18px;
  padding: 0 18px 18px; }
  .receipt div {
    background: #F2F2F2;
    margin-bottom: 18px;
    padding: 18px; }
    .receipt div h1 {
      line-height: 1.2;
      margin-bottom: 4.5px; }
    .receipt div p {
      color: #28ade3;
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 9px; }
    .receipt div a {
      font-weight: normal; }
    .receipt div span {
      color: #696969; }
  .receipt > a {
    display: block;
    font-size: 18px;
    font-weight: normal;
    text-align: center; }
  .receipt .receipt-x-print {
    margin-bottom: 18px; }

/*
Requirements List

Markup:
<section id="requirements_list" class="requirements-list">
  <section class="controls">
    <button id="requirements-toggle" class="toggle">Toggle Requirements</button>
    <div class="timer">
      <div style="" class="timer-content">
        <section>
          <h1 id="timer_title">Timer Requirement</h1>
          <p class="tooltip"><a href="#whats-this-modal">What's this?</a></p>
        </section>
        <p id="requirements-timer" class="timer-value">2:46</p>
      </div>
    </div>
  </section>
  <ul id="requirements-categories" class="categories">
    <li>
      <h3>Introduction</h3>
      <ul id="introduction-requirements">
        <li class="complete"><a class="state-notifications" href="#">State Notifications</a></li>
        <li class="complete"><a class="intro-video" href="/peterson/">Introductory Video</a></li>
        <li class="state-county-reqs in-progress"><a class="state-county-reqs" href="#">State/County Requirements</a></li>
      </ul>
    </li>
    <li>
      <h3>Course Content</h3>
      <ul id="course-content-requirements">
        <li class="in-progress"><a href="#" class="chapter_view" data-chapterid="3">01: Carrying Messages</a></li>
        <li class="in-progress"><a href="#" class="chapter_view" data-chapterid="4">02: Put Downs</a></li>
        <li class=""><a href="#" class="chapter_view" data-chapterid="5">03: Money Problems</a></li>
        <li class=""><a href="#" class="chapter_view" data-chapterid="6">04: Questioning</a></li>
        <li class=""><a href="#" class="chapter_view" data-chapterid="7">05: Long Distance Parenting</a></li>
        <li class="unavailable "><a href="#" class="chapter_view" data-chapterid="9">06: Conclusion</a></li>
      </ul>
    </li>
  </ul>
</section>

Styleguide 3.17
*/
section.requirements-list {
  background: #155a76;
  box-sizing: border-box;
  color: #fff;
  margin-top: -54px;
  position: fixed;
  top: 100%;
  transition: top .5s ease-out;
  z-index: 101;
  width: 100%; }
  @media (min-width: 950px) {
    section.requirements-list {
      bottom: auto;
      flex-basis: 340px;
      height: auto;
      left: auto;
      margin-top: 0;
      position: relative;
      width: auto;
      z-index: 0; } }
  section.requirements-list .toggle {
    background: url("images/requirements-list-toggle.svg") no-repeat 27px 13.5px;
    border: 0;
    border-right: 1px solid #fff;
    display: block;
    height: 54px;
    outline: none;
    padding: 0 18px 0 45px;
    position: relative;
    text-indent: -9999px;
    width: 23px; }
    @media (min-width: 950px) {
      section.requirements-list .toggle {
        display: none; } }
    section.requirements-list .toggle:before {
      background: url("images/requirements-list-toggle-arrow.svg") center center no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 7px;
      left: 9px;
      margin-top: -3.5px;
      position: absolute;
      top: 50%;
      width: 10px; }
  section.requirements-list .categories {
    box-sizing: border-box;
    height: 100%;
    list-style: none;
    overflow-y: auto;
    padding: 54px 0 18px; }
    @media (min-width: 950px) {
      section.requirements-list .categories {
        height: auto;
        overflow-y: auto; } }
    section.requirements-list .categories h3 {
      font-weight: bold;
      margin-bottom: 9px; }
    section.requirements-list .categories .instructor_section_view.active {
      color: #28ade3; }
    section.requirements-list .categories > li {
      margin: 36px 0 18px 36px; }
      section.requirements-list .categories > li.current h3 a {
        color: #28ade3; }
      section.requirements-list .categories > li.current ul li.current a {
        color: #28ade3;
        font-weight: bold; }
      section.requirements-list .categories > li ul.default-content > li a {
        background: url("images/requirements-list-not-attempted.png") right center no-repeat; }
      section.requirements-list .categories > li ul.default-content > li.failed a {
        background: url("images/requirements-list-not-failed.png") right center no-repeat; }
      section.requirements-list .categories > li ul.default-content > li.in-progress a {
        background: url("images/requirements-list-inprogress.png") right center no-repeat; }
      section.requirements-list .categories > li ul.default-content > li.complete a {
        background: url("images/requirements-list-complete.png") right center no-repeat; }
      section.requirements-list .categories > li ul.default-content > li.unavailable a {
        opacity: .5;
        pointer-events: none;
        cursor: default; }
      section.requirements-list .categories > li ul.instructor-content > li > a {
        background: url("images/instructor-content-expand.png") right center no-repeat; }
      section.requirements-list .categories > li ul.instructor-content > li.expanded > a {
        background: url("images/instructor-content-collapse.png") right center no-repeat; }
      section.requirements-list .categories > li ul.instructor-content ul.instructor-submenu {
        margin: 0; }
        section.requirements-list .categories > li ul.instructor-content ul.instructor-submenu li {
          display: none;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: none; }
          section.requirements-list .categories > li ul.instructor-content ul.instructor-submenu li a {
            padding: 5px 20px; }
      section.requirements-list .categories > li ul.instructor-content .expanded ul.instructor-submenu li {
        display: block; }
      section.requirements-list .categories > li ul {
        margin-left: 0;
        padding: 0;
        margin: 0 0 18px; }
        section.requirements-list .categories > li ul li {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
          section.requirements-list .categories > li ul li a {
            font-weight: normal;
            padding: .5em 0;
            margin-right: 1em;
            padding-right: 36px;
            display: block;
            text-overflow: ellipsis; }
      section.requirements-list .categories > li a {
        color: #fff; }
  section.requirements-list .controls {
    background: #013850;
    display: flex;
    height: 54px;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 50; }
  section.requirements-list .timer {
    width: 100%; }
  section.requirements-list .timer-content {
    align-items: center;
    display: flex;
    height: 54px;
    justify-content: space-between;
    padding: 0 18px; }
    section.requirements-list .timer-content h1 {
      font-size: 14px; }
    section.requirements-list .timer-content .timer-value {
      font-size: 36px;
      font-weight: bold;
      line-height: 1em; }
    section.requirements-list .timer-content p.tooltip {
      color: #28ade3;
      line-height: 1em;
      position: relative; }
      section.requirements-list .timer-content p.tooltip span {
        background: #28ade3;
        bottom: 10px;
        border-radius: 5px;
        color: #fff;
        display: block;
        font-weight: normal;
        font-size: 14px;
        left: -9999px;
        line-height: 1.5em;
        padding: 9px;
        position: absolute;
        white-space: normal;
        width: 1 54px;
        z-index: 1000; }
      section.requirements-list .timer-content p.tooltip:hover span {
        bottom: 105%;
        left: 50%;
        margin-left: -75px; }
    section.requirements-list .timer-content.ticktock h2 {
      background: #28ade3;
      width: 80%;
      color: #fff;
      padding: 3px 1em; }
    section.requirements-list .timer-content.ticktock h1 {
      color: #28ade3; }
  section.requirements-list.done h2 {
    background: #9aba38;
    width: 80%;
    color: #fff;
    padding: 3px 1em; }
  section.requirements-list.done h1 {
    color: #9aba38; }
  section.requirements-list.is-expanded {
    bottom: auto;
    height: 100%;
    margin-top: 0;
    top: 0;
    width: 100%; }
    section.requirements-list.is-expanded .toggle:before {
      animation: turn-in .5s ease-out forwards; }
  section.requirements-list.is-collapsed .toggle:before {
    animation: turn-out .5s ease-out forwards; }

/*
Resources

Markup:
<div class="resources-container l-containerhorizontal">
  <header class="page-title page-title-course">
    <h1>Resources</h1>
  </header>
  <div class="resources-l-course">
    <nav class="navigation navigation-page glossary-navigation" id="glossary-navigation">
      <header>
        <h2 class="navigation-title">Glossary Menu</h2>
        <button class="menu-toggle is-inactive" id="menu-toggle"><span class="visually-hidden"> Menu</span></button>
      </header>
      <ul class="menu menu-page is-inactive">
        <li class="menu-item menu-item-page" onclick="show_glossary('Active Listening', '/media/audio/ACTIVE_LISTENING_1.mp3');"><span>Active Listening</span></li>
        <li class="menu-item menu-item-page" onclick="show_glossary('Empathy', '/media/audio/EMPATHY_1.mp3');"><span>Empathy</span></li>
        <li class="menu-item menu-item-page" onclick="show_glossary('I Message', '/media/audio/I_MESSAGES_1.mp3');"><span>I Message</span></li>
        <li class="menu-item menu-item-page" onclick="show_glossary('Impulse Control', '/media/audio/IMPULSE_CONTROL_1_1.mp3');"><span>Impulse Control</span></li>
        <li class="menu-item menu-item-page" onclick="show_glossary('Polite Request', '/media/audio/POLITE_REQUEST_1.mp3');"><span>Polite Request</span></li>
      </ul>
    </nav>
    <section class="resources-content">
      <article class="glossary-definition text-container" style="" term="Active Listening" id="glossary-term-active-listening">
        <h1>Active Listening</h1>
        <p>When we actively listen to someone, we are making a real effort to hear and understand what they are saying.  We are not thinking about our own reaction to what is being said; instead we are listening deeply and with all of our attention. The next step is to let the other person know you have been listening by doing one of three things:<br>
          1) rewording or paraphrasing what they have said and repeating it back to them;<br>
          2) stating the meaning of what they said;<br>
          3) guessing at and stating the underlying feeling of what they said.<br>
          <br>
          Examples of each of these three levels of active listening follow:<br>
          <br>
          1) Rewording  Speaker:  I can’t believe how long it took me to get here. The traffic was pretty bad. Listener:  The traffic was bad and it took you longer than you thought to get here.<br>
          <br>
          2) Meaning  Listener:  You were surprised the trip took so long due to traffic delays.<br>
          <br>
          3) Feeling  Listener:  You sound frustrated that you got here later than you
          expected.<br>
          <br>
          Active listening shows respect for the speaker and encourages more open and pleasant communication. Parents need to use this skill with their children and each other.</p>
      </article>
    </section>
  </div>
</div>

Styleguide 3.18
*/
.resources-container {
  margin-bottom: 18px; }
  .resources-container .page-title {
    margin-bottom: 0; }

@media (min-width: 950px) {
  .resources-l-course {
    display: flex;
    flex-direction: row;
    padding: 0 18px; }
    .resources-l-course .resources-navigation {
      flex-basis: 25%; } }

.resources-content {
  padding-top: 18px; }
  @media (min-width: 950px) {
    .resources-content {
      flex-basis: 75%; } }

/*
Rule

Markup:
<hr class="rule">

Styleguide 3.56
*/
.rule {
  border: 0;
  border-top: 1px solid #F2F2F2;
  margin: 0;
  padding: 0; }

/*
Score

Markup:
<div id="quiz_score">
  <h2>Quiz Results</h2>
  <h3>Your score on the Questioning quiz was:</h3>
  <h1>38%</h1>
  <h3 id="passing_header">Unfortunately, you need at least 70% to pass. Please review the Chapter and attempt the Quiz again.</h3>
  <a class="button" id="repeat_chapter" href="">Try Questioning Again</a>
  <p>or <a id="return_to_dashboard" href="">Return to Gallery</a></p>
</div>

Styleguide 3.20
*/
#quiz_score {
  background: #fff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  margin: 18px;
  overflow: hidden;
  padding: 36px 18px;
  text-align: center;
  width: auto; }
  @media (min-width: 960px) {
    #quiz_score {
      margin: 36px auto;
      padding: 36px 72px; } }
  #quiz_score h1, #quiz_score h3, #quiz_score .button {
    margin-bottom: 9px; }
  #quiz_score h1 {
    font-size: 64px;
    line-height: 1em; }
    @media (min-width: 960px) {
      #quiz_score h1 {
        font-size: 120px; } }
  #quiz_score h2 {
    font-size: 18px;
    margin-bottom: 0; }
    @media (min-width: 960px) {
      #quiz_score h2 {
        font-size: 24px; } }
  #quiz_score h3 {
    font-size: 14px;
    line-height: 1.5em; }
    @media (min-width: 960px) {
      #quiz_score h3 {
        color: #555555;
        font-size: 18px; } }
  #quiz_score .button {
    width: 100%; }
  #quiz_score div.skills-practice {
    width: 100%;
    float: none;
    padding: 18px 0 0 0;
    border-top: 1px solid #F2F2F2; }
    #quiz_score div.skills-practice ul {
      display: flex;
      flex-direction: column; }
      @media (min-width: 960px) {
        #quiz_score div.skills-practice ul {
          flex-direction: row; } }
      #quiz_score div.skills-practice ul li {
        float: left;
        text-align: left;
        flex: 1 0 auto; }
        @media (min-width: 960px) {
          #quiz_score div.skills-practice ul li {
            flex: 1 1 50%; } }
        @media (min-width: 960px) {
          #quiz_score div.skills-practice ul li:nth-child(odd) {
            margin-right: 18px; } }
        #quiz_score div.skills-practice ul li a {
          padding-bottom: .5em; }

/*
Skills Practice

Markup:
<div class="skills-practice " style="">
  <header class="page-title">
    <h1>Skills Practice</h1>
    <p> </p>
  </header>
  <ul>
    <li class="completed" id="skillpractice-1">
      <a class="skillpractice_view" href="#">Active Listening</a>
      <p><a class="skillpractice" href="#"></a></p>
    </li>
    <li class="completed" id="skillpractice-2">
      <a class="skillpractice_view" href="#">I Messages</a>
      <p><a class="skillpractice" href="#"></a></p>
    </li>
    <li class="completed" id="skillpractice-3">
      <a class="skillpractice_view" href="#">Self-Talk</a>
      <p><a class="skillpractice" href="#"></a></p>
    </li>
    <li class="" id="skillpractice-4">
      <a class="skillpractice_view" href="#">Reframing</a>
      <p><a class="skillpractice" href="#"></a></p>
    </li>
  </ul>
</div>

Styleguide 3.21
*/
/*
Skills Practice Resource

Markup:
<div class="quiz_question quiz_question_resource">
  <div class="text">
    <div class="image">
      <img src="/images/download.svg" width="26" height="49">
    </div>
    <div class="description">
      <p class="file_name">Mindfulness_Meditation.mp3</p>
      <p>Meditation MP3 Download</p>
    </div>
  </div>
  <div class="action">
    <a class="button" href="/media/skillpractice/attachment/Mindfulness_Meditation.mp3">Download</a>
  </div>
</div>

Styleguide 3.21.1
*/
/*
Skills Practice Question

Markup:
<section class="quiz_question" id="question_2">
  <ul class="quiz">
    <li>
      <p class="question">Shandra has been putting off doing her homework until late at night when she is tired. She gets poor grades. When Mom asks her to start her homework earlier, Shandra angrily says to get off her case.  Mom says:  </p>
    </li>
    <li class="clearfix">
      <span id="answer_6" class="correct">Correct</span>
      <label for="answer_6">a) You don’t like it when I am the homework reminder machine.</label>
    </li>
    <li class="clearfix">
      <span id="answer_7" class="incorrect">Incorrect</span>
      <label for="answer_7">b) Homework is not a punishment. No one likes to do it, but we all had to. </label>
    </li>
    <li class="clearfix">
      <span class="incorrect">Incorrect answer</span>
      <label for="answer_8">c)  You would be less frustrated if you started earlier.</label>
    </li>
    <li class="clearfix">
      <input type="radio" name="question_9" id="answer_9" value="9">
      <label for="answer_9">d) I'm sorry, I should not have pressured you.</label>
    </li>
  </ul>
  <div id="check_answer" class="clearfix">
    <button class="button red">Check Answer</button>
  </div>
  <p class="feedback"><span class="incorrect">Incorrect:</span> Mom gives advice instead of reflecting.</p>
</section>

Styleguide 3.21.2
*/
.skills-practice ul {
  margin: 0 18px; }
  .skills-practice ul li {
    margin: 0 0 18px;
    background: whitesmoke url("images/skillspractice-incomplete.png") 7px 7px no-repeat; }
    .skills-practice ul li:hover {
      background-color: #ededed; }
    .skills-practice ul li.completed {
      background: rgba(154, 186, 56, 0.2) url("images/skillspractice-complete.png") 7px 7px no-repeat; }
    .skills-practice ul li p {
      padding: 0 2em .5em 3.5em; }
      .skills-practice ul li p a {
        font-size: 14px;
        color: #000;
        padding: 0; }
    .skills-practice ul li a {
      cursor: pointer;
      padding: .5em 1em 0em 2.5em;
      display: block;
      font-size: 18px; }

.quiz_question {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  margin: 18px;
  padding: 18px; }
  .quiz_question .feedback {
    margin-top: 18px; }
  .quiz_question ul.quiz li {
    display: flex;
    margin-bottom: 18px;
    margin-bottom: 25px; }
    .quiz_question ul.quiz li:first-child {
      margin-bottom: 0; }
    .quiz_question ul.quiz li h3 {
      margin-bottom: 25px; }
  .quiz_question ul.quiz [type=radio] {
    display: block;
    flex: 0 0 25px;
    margin: 9px 9px 0 0;
    cursor: pointer; }
  .quiz_question ul.quiz label {
    flex: 1;
    line-height: 1.62em;
    display: block;
    font-size: 18px; }
  .quiz_question ul.quiz span {
    flex: 0 0 25px;
    margin: 4.5px 9px 0 0; }
    .quiz_question ul.quiz span.correct {
      background: url("images/skillspractice-correct.png") no-repeat;
      width: 25px;
      height: 18px;
      display: inline-block;
      text-indent: -9999px; }
    .quiz_question ul.quiz span.incorrect {
      background: url("images/skillspractice-incorrect.png") no-repeat;
      width: 19px;
      height: 19px;
      text-indent: -9999px; }
  .quiz_question span.incorrect {
    font-weight: bold;
    color: #b20000; }
  .quiz_question span.correct {
    font-weight: bold;
    color: #2d5f00; }
  .quiz_question .question {
    font-size: 18px;
    line-height: 1.2;
    color: #26111a;
    font-weight: bold;
    padding-bottom: 20px; }
  .quiz_question #check_answer {
    padding-top: 18px;
    border-top: 1px solid #F2F2F2; }
    .quiz_question #check_answer .button {
      width: 100%; }
      @media (min-width: 960px) {
        .quiz_question #check_answer .button {
          padding: 9px 18px;
          width: auto; } }

@media (min-width: 400px) {
  .quiz_question_resource {
    display: inline-block; } }

.quiz_question_resource .text {
  display: flex; }

.quiz_question_resource .image {
  flex: 1 1 auto;
  margin-right: 18px; }

.quiz_question_resource .description {
  flex: 1 1 auto; }

.quiz_question_resource .file_name {
  font-weight: bold; }

.quiz_question_resource .action {
  border-top: 1px solid #F2F2F2;
  margin-top: 9px;
  padding-top: 18px;
  padding-left: 36px;
  padding-right: 36px; }

.quiz_question_resource .button {
  width: 100%;
  padding-bottom: 9px;
  padding-top: 9px; }

.next_skill {
  display: block;
  margin: 0 auto 18px; }

/*
State Requirements

Markup:
<section class="state-requirements-module">
  <ul>
    <li class="complete">
      <h3>MN Child Custody &amp; Parenting Time (Visitation)</h3>
      <p></p>
      <ul>
        <li class="complete">
          <h4>Read:  Basics on Child Custody &amp; Parenting Time (Visitation)</h4>
          <p>Here you will learn more about (1) Types of Custody in Minnesota; (2) What is "parenting time?"; (3) Going to Court without a lawyer; (4) Fees and Costs; and much more!</p>
          <a href="http://www.mncourts.gov/selfhelp/?page=1810" id="17" target="_blank" class="button">View Resource</a>
        </li>
        <li class="complete">
          <h4>Read:  A Parental Guide to Making Child-Focused Parenting Time Decisions</h4>
          <p>Prepared by the MN Supreme Court Task Force -- provides additional information on age appropriate considerations based on the developmental stages of your child.  </p>
          <a href="http://online.divorce-education.com/media/cms/pdf/Parenting%20%20Time%20Pamphlet%20(MN%20AOC)%20English.pdf" id="19" target="_blank" class="button">View Resource</a>
        </li>
      </ul>
    </li>
    <li class="incomplete">
      <h3>Child Support In Minnesota</h3>
      <p></p>
      <ul>
        <li class="incomplete">
          <h4>Read:  Child Support Basics in Minnesota</h4>
          <p>This booklet provides basic information about how child support is set, collected and changed. It has information on Minnesota’s child support guidelines called “Income Shares.” </p>
          <a href="http://online.divorce-education.com/media/cms/pdf/MN%20Legal%20Aid%20Booklet%20-%20Child%20Support%20Basics%20(rivsed%207th%20edition%2C%202012)%20ENGLISH.pdf" id="26" target="_blank" class="button">View Link and Complete Requirement</a>
        </li>
        <li class="incomplete">
          <h4>Read: Understanding Child Support in Minnesota</h4>
          <p>This handbook gives general information about Minnesota’s child support program. Because parentage and child support are important issues, you may wish to contact an attorney. The Minnesota Department of Human Services (DHS) Child Support Enforcement Division and county child support agencies can help you if you apply for or are referred for child support services, but they cannot give you legal advice about your case.</p>
          <a href="http://online.divorce-education.com/media/cms/pdf/MN%20DHS%20-%20Understanding%20Child%20Support%20(ENGLISH)%202011.pdf" id="28" target="_blank" class="button">View Link and Complete Requirement</a>
        </li>
      </ul>
    </li>
  </ul>
</section>

Styleguide 3.22
*/
.state-requirements-module {
  box-sizing: border-box; }
  .state-requirements-module .button {
    display: inline-block;
    padding: 9px 18px; }
  .state-requirements-module h3,
  .state-requirements-module h4 {
    background-repeat: no-repeat;
    line-height: 1.2em;
    min-height: 25px;
    padding-left: 36px; }
  .state-requirements-module h3 {
    background-position: 18px 0;
    margin-bottom: 9px;
    padding: 0 18px 0 54px; }
  .state-requirements-module h4 {
    font-weight: bold;
    margin-bottom: 9px;
    padding-left: 36px;
    padding-top: 3px; }
  .state-requirements-module p {
    margin-bottom: 9px;
    overflow: hidden; }
  .state-requirements-module ul > li {
    margin-bottom: 18px; }
    @media (min-width: 960px) {
      .state-requirements-module ul > li {
        border-top: 1px solid #ccc;
        padding-top: 18px; }
        .state-requirements-module ul > li:first-of-type {
          border-top: 0;
          padding-top: 0; } }
    .state-requirements-module ul > li p {
      margin-left: 18px; }
  .state-requirements-module ul ul {
    border-top: 1px solid #ccc;
    margin-top: 18px; }
    @media (min-width: 960px) {
      .state-requirements-module ul ul {
        border-top: 0;
        margin-left: 36px; } }
  .state-requirements-module li li {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
    padding-left: 18px;
    padding-right: 18px; }
    @media (min-width: 960px) {
      .state-requirements-module li li {
        display: block; } }
    .state-requirements-module li li p,
    .state-requirements-module li li .button {
      margin-left: 36px; }
    .state-requirements-module li li:first-child {
      margin-top: 36px; }
  .state-requirements-module .requirements-list {
    padding-left: 18px; }
  .state-requirements-module .incomplete > h3,
  .state-requirements-module .incomplete > h4 {
    background-image: url("images/check-incomplete.png"); }
  .state-requirements-module .complete > h3,
  .state-requirements-module .complete > h4 {
    background-image: url("images/check-complete.png"); }

/*
Text Container

Markup:
<article class="text-container l-containerhorizontal l-containerhorizontal-small">
  <h1>HTML Ipsum Presents</h1>
  <p><strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut felis.</p>
  <h2>Header Level 2</h2>
  <ol>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
  </ol>
  <blockquote><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.</p></blockquote>
  <h3>Header Level 3</h3>
  <ul>
    <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
    <li>Aliquam tincidunt mauris eu risus.</li>
  </ul>
  <pre><code>
    #header h1 a {
    display: block;
    width: 300px;
    height: 80px;
    }
  </code></pre>
</article>

Styleguide 3.23
*/
.text-container {
  padding-left: 18px;
  padding-right: 18px; }
  .text-container h1,
  .text-container h2,
  .text-container h3,
  .text-container h4,
  .text-container h5,
  .text-container h6 {
    line-height: 1.2; }
  .text-container h1,
  .text-container h2,
  .text-container h3,
  .text-container h4,
  .text-container h5,
  .text-container h6,
  .text-container p,
  .text-container ol,
  .text-container ul,
  .text-container blockquote,
  .text-container figure,
  .text-container address {
    margin-bottom: 18px; }
  .text-container li {
    margin-bottom: 9px; }
  .text-container ul li {
    list-style: disc inside; }
  .text-container ol li {
    list-style: decimal inside; }
  .text-container strong {
    font-weight: bold; }
  .text-container em {
    font-style: italic; }

.text-container-padding-none {
  padding-left: 0;
  padding-right: 0; }

/*
text-body-color

Markup:
<div class="tex-body-color">
Body text.
</div>

Styleguide 3.50
*/
.text-body-color {
  color: #696969; }

.video {
  padding: 0 18px;
  margin: 0 0 18px; }
  @media (min-width: 960px) {
    .video {
      margin-bottom: 36px; } }

/*
visuallyhidden

Visualy hides an object

Markup:
  <div>The div bellow me is invisible</div>
  <div class="visuallyhidden">I'm invisible</div>


Styleguide 3.54
*/
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
Utility: Text

Markup:
<p class="u-text-small">Small</small>

Styleguide 3.58
*/
.u-text-small {
  font-size: 12px; }

/*
Unregistered Gift

Markup:
  <div class="page-title unregisteredGift">
      <div>
          <h1>tifa.tifa@gmail.com</h1>
          <a>Change Email</a>
          <p class="unregisteredGift-x-giftAccount">Gift Account</p>
          <p class="unregisteredGift-x-purchase">Purchased 06/12/2016 by parent@example.com</p>
          <p class="unregisteredGift-x-notActivated">Not Activated</p>
      </div>
      <div class="unregisteredGift-x-stackedButton">
          <a class="button" href="#">Resend Invite</a>
          <a class="button" href="#">Cancel Gift Invite</a>
      </div>
  </div>

Styleguide 3.47
*/
.unregisteredGift {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .unregisteredGift h1 {
    font-size: 36px;
    line-height: 38px; }
  .unregisteredGift a {
    margin-bottom: 18px;
    display: block; }
  @media (min-width: 960px) {
    .unregisteredGift {
      align-items: flex-start;
      flex-direction: row; } }
  .unregisteredGift section {
    margin-bottom: 18px; }
    @media (min-width: 960px) {
      .unregisteredGift section {
        margin-bottom: 0; } }
  .unregisteredGift p.unregisteredGift-x-purchase,
  .unregisteredGift p.unregisteredGift-x-giftAccount {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0; }
  .unregisteredGift p.unregisteredGift-x-notActivated {
    margin-top: 18px; }
  .unregisteredGift .unregisteredGift-x-stackedButton {
    display: flex;
    flex-direction: column; }
    .unregisteredGift .unregisteredGift-x-stackedButton a:first-of-type {
      margin-bottom: 18px; }

/*
Upsell

Markup:
<article class="upsell">
  <div class="upsell-x-image">
    <img src="http://placehold.it/128x96">
  </div>
  <div class="upsell-x-text">
    <h3><a href="#">Upsell Title</a></h3>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
  </div>
</article>

Styleguide 3.59
*/
.upsell h3 {
  font-size: 18px; }

.upsell p {
  font-size: 12px; }

@media (min-width: 400px) {
  .upsell {
    display: flex; }
    .upsell .upsell-x-image {
      flex: 0 0 auto;
      margin-right: 18px; }
    .upsell .upsell-x-text {
      flex: 1 1 100%; } }

/*
Wrap

Wrap is used to set the background / text color of an item.

Default - White background
.wrap-branddark - Dark brand color
.wrap-lightgrey - A light grey background
.wrap-course - Wraps the course content (videos, quizes, etc)

Markup:
<div class="wrap {$modifiers}">I am wrapped</div>

Styleguide 3.33
*/
.wrap {
  background-color: #fff; }

.wrap-branddark {
  background-color: #155a76;
  color: #fff; }

.wrap-lightgrey {
  background-color: #F2F2F2; }

.wrap-course {
  background: #f4f4f4; }
  @media (min-width: 950px) {
    .wrap-course {
      box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.5); } }

/*
Module Rules (Marketing)

Styleguide 4.0
*/
/*
Button Callout

Markup:
<section class="l-wrap wrap buttoncallout">
  <a href="" class="button button-ppc">Sign Up Now</a>
  <p>Guaranteed acceptance in Maricopa County, Arizona!</p>
</section>

Styleguide 4.2
*/
.buttoncallout {
  text-align: center; }
  .buttoncallout p {
    color: #155a76;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 18px; }
    @media (min-width: 650px) {
      .buttoncallout p {
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
        width: 337px; } }

/*
Course Overview

Markup:
<div class="l-wrap wrap courseoverview">
  <section class="chapters">
    <h2>Covered in the course</h2>
    <ul>
      <li>Money Problems</li>
      <li>Children Carrying Messages</li>
      <li>Putting Down the Other Parent</li>
      <li>Long Distance Parenting</li>
      <li>Quizzing Children About the Other Parent</li>
    </ul>
  </section>
  <section class="praise" id="praise">
    <h2>Praise for Children In Between</h2>
    <div class="slider">
      <div>
        <blockquote>
          <h4>"Children in Between Online made me think very hard about my actions with my soon to be ex-spouse and what is best for our son."</h4>
          <cite>Molly Dever, Shepherdsville, KY</cite>
        </blockquote>
      </div>
      <div>
        <blockquote>
          <h4>"This program was recommended by our co-parenting counselor and I would recommend it to other couples who are thinking about or getting a divorce involving children.  In fact, I have recommended it to one of my co-workers already. What was most useful for me was the different techniques of communication - self talk, "I" statements, etc."</h4>
          <cite>Diane Hodges, Fairbanks, AK</cite>
        </blockquote>
      </div>
      <div>
        <blockquote>
          <h4>"After taking CIBO and putting the skills I learned into practice, my kids are much more at ease and know that they can talk to daddy about anything, and it's OK. I recommend this class to everyone!"</h4>
          <cite>Richard Demarco, Grants Pass, Oregon</cite>
        </blockquote>
      </div>
      <div>
        <blockquote>
          <h4>"We've been using Children In Between program distributed by the Center for Divorce Education for 10 years. It's been life changing for so many families!"</h4>
          <cite>Doak Givan, Executive Director of Family Services Center, Inc.</cite>
        </blockquote>
      </div>
      <div>
        <blockquote>
          <h4>"I highly recommend CIBO to anyone who wants to have a closer, more respectful relationship with the other parent and children."</h4>
          <cite>John Parker, Roseburg, Oregon</cite>
        </blockquote>
      </div>
      <div>
        <blockquote>
          <h4>"Excellent...Superb...I was impressed with the quality."</h4>
          <cite>Hon. Cheryl Karner, Domestic Relations Judge</cite>
        </blockquote>
      </div>
    </div>
  </section>
</div>

Styleguide 4.3
*/
.courseoverview {
  display: flex;
  flex-direction: column; }
  .courseoverview > * {
    border: 1px solid rgba(40, 173, 227, 0.5);
    padding: 36px 54px; }
    .courseoverview > *:first-child {
      margin-bottom: 18px; }
    .courseoverview > *.chapters ul {
      margin-top: 2em; }
      .courseoverview > *.chapters ul li {
        font-size: 18px;
        padding: .75em 0;
        color: #28ade3; }
    .courseoverview > *.praise h2 {
      font-size: 24px;
      padding: .5em; }
    .courseoverview > *.praise div.slider {
      margin-top: 2em; }
      .courseoverview > *.praise div.slider h4 {
        font-size: 18px; }
    .courseoverview > * h2 {
      font-size: 30px;
      color: #155a76;
      border-bottom: 1px solid rgba(21, 90, 118, 0.25);
      padding: .5em 0; }
  @media (min-width: 650px) {
    .courseoverview {
      flex-direction: row; }
      .courseoverview > * {
        flex: 0 0 50%; }
        .courseoverview > *:first-child {
          margin-bottom: 0;
          margin-right: 18px; }
        .courseoverview > *.praise {
          max-width: 50%; } }

/*
CTA

Markup:
<div class="cta l-wrap">
  <a class="button" href="#">Sign-Up</a>
</div>

Styleguide 4.4
*/
.cta a {
  font-size: 18px;
  width: 100%; }

@media (min-width: 650px) {
  .cta a {
    display: block;
    margin: 0 auto;
    max-width: 290px; } }

/*
CTA Audience

Markup:
<div class="ctaaudience l-wrap l-wrap-spanmobile">
  <a href="#">
    <img src="/images/homepage-audience-attorneys.svg" alt="Scales of justice" width="66" height="52">
    <h1>For Attorneys</h1>
    <p>Children in Between Online offers your clients a fantastic and pro-active way to help their kids throught this tough time. This is a quick win for you.</p>
  </a>
  <a href="#">
    <img src="/images/homepage-audience-courts.svg" alt="Gavel" width="58" height"52">
    <h1>For Courts</h1>
    <p>The best online parenting course makes it easy for residents in your jurisdiction to get quality divorce education even when they can’t make it to an in-person class.</p>
  </a>
</div>

Styleguide 4.5
*/
.ctaaudience {
  align-items: stretch;
  background: #28ade3;
  display: flex;
  flex-direction: column; }
  .ctaaudience a {
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-weight: normal;
    padding: 36px 18px;
    text-align: center; }
    .ctaaudience a:not(:last-child) {
      border-bottom: 9px solid #fff; }
    .ctaaudience a:hover {
      background-color: #198fbf; }
  .ctaaudience img {
    margin-bottom: 9px; }
  .ctaaudience h1 {
    font-size: 24px;
    margin-bottom: 4.5px; }
  @media (min-width: 650px) {
    .ctaaudience {
      flex-direction: row; }
      .ctaaudience a {
        flex: 1;
        padding-bottom: 72px;
        padding-top: 54px; }
        .ctaaudience a:not(:first-child) {
          border-left: 2.25px solid #fff; }
        .ctaaudience a:not(:last-child) {
          border-bottom: 0;
          border-right: 2.25px solid #fff; } }

/*
CTA Overview

Markup:
<div class="l-childrenvertical-x-exclude ctaoverview l-wrap l-wrap-spanmobile">
  <section>
    <h1>Children in Between</h1>
    <h2>A Co-Parenting Program for Divorcing and Separating Parents.</h2>
    <ul>
      <li>Instant Printable Certificate upon completion</li>
      <li>No need to take an in-person class</li>
      <li>Complete the course 100% online</li>
      <li>Accessible 24/7</li>
      <li>Only 3-5 hours to complete</li>
      <li>Pay no more than $45.95</li>
    </ul>
    <div>
      <a href="" class="button">Sign-Up Now</a>
      <img srcset="/images/homepage-ctaoverview-courtaccepted-2x.png 2x" src="/images/homepage-ctaoverview-courtaccepted.png" alt="Court Accepted, 100% Guranteed Seal" width="150" height="150">
      <p>Instant Certificate!<br> Guranteed Court Approval</p>
    </div>
  </section>
</div>

Styleguide 4.6
*/
.ctaoverview {
  line-height: 1.16;
  overflow: hidden;
  position: relative; }
  .ctaoverview::before, .ctaoverview::after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0; }
  .ctaoverview::before {
    background-image: url("images/Pic3.jpg");
    display: none;
    left: 0; }
  .ctaoverview::after {
    background-image: url("images/Pic4.jpg");
    right: 0;
    width: 100%; }
  .ctaoverview section {
    background-color: rgba(10, 52, 69, 0.85);
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    padding: 36px 18px;
    position: relative;
    text-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
    z-index: 50; }
    .ctaoverview section img {
      display: block;
      float: right;
      height: auto;
      margin-bottom: 9px;
      width: 32%; }
  .ctaoverview h1 {
    line-height: 1;
    margin-bottom: 4.5px; }
  .ctaoverview h2 {
    font-size: 18px;
    margin-bottom: 18px; }
  .ctaoverview ul {
    font-size: 18px;
    margin-bottom: 9px;
    padding-left: 27px; }
    .ctaoverview ul li {
      list-style: disc outside;
      margin-bottom: 4.5px; }
  .ctaoverview a {
    float: left;
    font-size: 18px;
    margin-top: 7%;
    text-shadow: none;
    width: 65%; }
  .ctaoverview div {
    text-align: center; }
    .ctaoverview div p {
      clear: both;
      font-size: 18px; }
  @media (min-resolution: 2dppx) {
    .ctaoverview::before {
      background-image: url("images/Pic3.jpg"); }
    .ctaoverview::after {
      background-image: url("images/Pic4.jpg"); } }
  @media (min-width: 650px) {
    .ctaoverview {
      display: flex; }
      .ctaoverview::before, .ctaoverview::after {
        width: 50%; }
      .ctaoverview::before {
        display: block; }
      .ctaoverview section {
        margin: 0 auto;
        max-width: 415px;
        padding-bottom: 18px;
        padding-left: 27px;
        padding-right: 27px;
        z-index: 50; }
        .ctaoverview section::after {
          clear: both;
          content: '';
          display: block;
          font-size: 0;
          height: 0;
          visibility: hidden; }
        .ctaoverview section img {
          width: 38%; }
      .ctaoverview a {
        width: 58%; }
      .ctaoverview div p {
        clear: none;
        float: left;
        margin-top: 9px;
        text-align: left; } }
  @media (min-width: 960px) {
    .ctaoverview {
      padding-bottom: 36px;
      padding-top: 36px; } }

/*
CTA Photo

Markup:
<article class="l-childrenvertical-x-exclude ctaphoto l-wrap">
  <img src="/images/homepage-courtacceptance.svg" alt="Judge icon" width="108" height="93">
  <h1>Guranteed Court Acceptance</h1>
  <p>Children in Between Online is commonly accepted by court systems across the United States to fulfill requirements regarding divorce education for parents, often replacing inconvenient and expensive in-person classes.</p>
  <a class="button" href="#">Sign-Up Now</a>
</article>

Styleguide 4.7
*/
.ctaphoto {
  background: url("images/ctaphoto.png") center center;
  background-size: cover;
  color: #fff;
  padding-bottom: 36px;
  padding-top: 36px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px; }
  .ctaphoto img {
    display: block;
    margin: 0 auto 18px; }
  .ctaphoto h1 {
    margin-bottom: 18px; }
  .ctaphoto p {
    font-size: 18px;
    margin-bottom: 18px; }
  .ctaphoto a {
    font-size: 18px;
    text-shadow: none;
    width: 100%; }
  @media (min-resolution: 2dppx) {
    .ctaphoto {
      background-image: url("images/ctaphoto-2x.png"); } }
  @media (min-width: 650px) {
    .ctaphoto {
      background-image: url("images/ctaphoto-desktop.png");
      padding-bottom: 54px;
      padding-top: 54px; } }
  @media (min-width: 650px) and (min-resolution: 2dppx) {
    .ctaphoto {
      background-image: url("images/ctaphoto-desktop-2x.png"); } }
  @media (min-width: 650px) {
      .ctaphoto p {
        margin-left: auto;
        margin-right: auto;
        max-width: 60%; }
      .ctaphoto a {
        display: block;
        margin: 0 auto;
        max-width: 290px; } }

/*

Markup:
<article class="ctaphoto ctaphoto-completecase l-wrap">
  <img src="http://placehold.it/73x73" alt="Complete Case Logo" width="73" height="73">
  <h1>Save thousands of dollars in legal fees without the cost of an attorney.</h1>
  <p>Visit CompleteCase.com and sign up for their services AND the Children in Between Online Program.</p>
  <a class="button button-outline" href="#">CompleteCase.com</a>
</article>

Styleguide 4.7.1
*/
.ctaphoto-completecase {
  background: #155a76;
  text-shadow: none; }
  .ctaphoto-completecase > *:not(:last-child) {
    margin-bottom: 18px; }
  .ctaphoto-completecase h1,
  .ctaphoto-completecase p {
    margin-left: auto;
    margin-right: auto;
    max-width: 654px; }
  .ctaphoto-completecase a {
    width: auto; }
  .ctaphoto-completecase h1 {
    font-size: 30px;
    font-weight: bold; }
  @media (min-resolution: 2dppx) {
    .ctaphoto-completecase {
      background: #155a76; } }
  @media (min-width: 650px) {
    .ctaphoto-completecase {
      background: #155a76; } }
  @media (min-width: 650px) and (min-resolution: 2dppx) {
    .ctaphoto-completecase {
      background: #155a76; } }
  @media (min-width: 650px) {
      .ctaphoto-completecase a {
        display: inline-block;
        max-width: none; } }

/*
CTA Quotes

Markup:
<div class="ctaquotes l-wrap">
  <div class="slider">
    <div>
      <blockquote>
        <p>&ldquo;This program was recommended by our co-parenting counselor and I would recommend it to other couple who are thinking about or getting a divorce involving children. In fact, I have recommended it to one of my co-workers already.&rdquo;</p>
        <cite><strong>Diange Hodges</strong> Fairbanks, AK</cite>
      </blockquote>
    </div>
  </div>
  <div>
    <p>Join more than <strong>50,000 parents</strong> who have completed our award-winning class! <strong>Recommended by 9 out of 10 parents.</strong></p>
    <a class="ctaquotes-x-button button" href="#">Sign-Up Now</a>
  </div>
</div>

Styleguide 4.8
*/
.ctaquotes p,
.ctaquotes cite,
.ctaquotes strong {
  color: #696969;
  font-size: 18px;
  line-height: 1.33; }

.ctaquotes p {
  margin-bottom: 18px; }

.ctaquotes blockquote {
  background: #F2F2F2;
  margin-bottom: 27px;
  padding: 18px;
  position: relative; }
  .ctaquotes blockquote::after {
    border: 36px solid transparent;
    border-left-color: #F2F2F2;
    bottom: -27px;
    content: '';
    display: block;
    height: 0;
    left: 54px;
    position: absolute;
    width: 0;
    z-index: 10; }
  .ctaquotes blockquote p,
  .ctaquotes blockquote cite {
    position: relative;
    z-index: 50; }
  .ctaquotes blockquote cite,
  .ctaquotes blockquote strong {
    display: block; }

.ctaquotes > :first-child {
  margin-bottom: 36px; }

.ctaquotes .ctaquotes-x-button {
  font-size: 18px;
  width: 100%; }

.ctaquotes .slick-next,
.ctaquotes .slick-prev {
  display: none !important; }

.ctaquotes .slick-dots {
  bottom: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: -9px 0 0;
  padding-left: 18px; }
  .ctaquotes .slick-dots li {
    display: block;
    margin: 0 4.5px 0 0; }
    .ctaquotes .slick-dots li:last-child {
      margin-right: 0; }
  .ctaquotes .slick-dots button {
    border-radius: 100%;
    box-shadow: 0 0 0 1px #696969 inset;
    display: block;
    height: 16px;
    margin: 0;
    transition: background-color 0.25s ease-in-out;
    width: 16px; }
    .ctaquotes .slick-dots button::before {
      display: none; }
  .ctaquotes .slick-dots .slick-active button {
    background-color: #696969; }

@media (min-width: 650px) {
  .ctaquotes {
    display: flex; }
    .ctaquotes > div {
      width: 50%; }
    .ctaquotes > :first-child {
      margin-bottom: 0;
      padding-right: 18px; }
    .ctaquotes > :last-child {
      padding-left: 18px;
      padding-top: 36px; }
    .ctaquotes .ctaquotes-x-button {
      width: auto; } }

/*
Features

Markup:
<article class="features l-wrap">
  <h1>Course Features</h1>
  <p>Children in Between is a scientifically proven to ensure that you learn the skills necessary to make divorce easier on your children.</p>
  <ul>
      <li>
        <img srcset="/images/feature-videos-2x.png 2x" src="/images/feature-videos.png" alt="TV Screen" width="100" height="100">
        <div>
          <h2>Video Scenarios</h2>
          <p>Watch videos with common co-parenting problems and then see different responses that produce positive and negative results.</p>
        </div>
      </li>
      <li>
        <img srcset="/images/feature-questions-2x.png 2x" src="/images/feature-questions.png" alt="Question mark" width="100" height="100">
        <div>
          <h2>Guided Questions</h2>
          <p>After each video scenario, CIBO presents the user with guided questions to explore how each problem can be addressed, and to increase your understanding of the situation.</p>
        </div>
      </li>
      <li>
        <img srcset="/images/feature-quizes-2x.png 2x" src="/images/feature-quizes.png" alt="Reinforcing Quizzes" width="100" height="100">
        <div>
          <h2>Reinforcing Quizzes</h2>
          <p>At the end of each chapter, you’ll have the opportunity to reinforce what you’ve learned by taking a quiz. Quizzes help ensure that you’ll be ready to handle situations in the future.</p>
        </div>
      </li>
      <li>
        <img srcset="/images/feature-skillspractice-2x.png 2x" src="/images/feature-skillspractice.png" alt="Star" width="100" height="100">
        <div>
          <h2>Skills Practice</h2>
          <p>You can spend time practicing and refining your skills in our Skills Practice section - meant to increase your confidence in the skills taught in CIBO.</p>
        </div>
      </li>
  </ul>
</article>

Styleguide 4.9
*/
.features h1 {
  margin-bottom: 9px;
  text-align: center; }

.features li {
  margin-bottom: 27px; }
  .features li p {
    color: #696969; }

.features h2 {
  font-size: 24px;
  margin-bottom: 0; }

.features img {
  display: block;
  height: auto;
  margin: 0 auto 9px;
  width: 100px; }

.features > p {
  color: #696969;
  margin-bottom: 27px;
  text-align: center; }

@media (min-width: 650px) {
  .features h1 {
    margin-bottom: 18px; }
  .features > p {
    font-size: 18px;
    margin-bottom: 0; }
  .features ul {
    display: flex;
    flex-wrap: wrap; }
  .features li {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 0;
    padding: 54px 36px 0;
    width: 50%; }
  .features img {
    flex: 0 0 100px; }
  .features div {
    padding-left: 18px; } }

/*
Photo Callout

Markup:
<section class="l-wrap l-wrap-spanmobile wrap wrap-lightgrey photocallout">
    <div class="text-container">
      <h3>Give your children the best chance.</h3>
      <p>The divorce of one’s parents is a major event in the life of a child. Children in Between Online ensures that the accompaning stress is minimized, the long-term psychological burden is eased, and the whole process is made more understandable and manageable for the parents so that children will benefit.</p>
    </div>
    <div>
      <img src="http://placehold.it/320x349" alt="Picture of women and children">
    </div>
  </section>

Styleguide 4.10
*/
.photocallout {
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  padding-top: 18px; }
  @media (min-width: 650px) {
    .photocallout {
      flex-direction: row; } }
  @media (min-width: 650px) {
    .photocallout p,
    .photocallout li {
      color: #696969;
      font-size: 18px; } }
  .photocallout img {
    display: block;
    height: auto;
    margin-bottom: 18px;
    width: 100%; }
    @media (min-width: 650px) {
      .photocallout img {
        border: 1px solid #000; } }
  .photocallout > :first-child {
    order: 2; }
    @media (min-width: 650px) {
      .photocallout > :first-child {
        margin-right: 54px;
        flex: 1;
        order: 1; } }
  .photocallout > :last-child {
    order: 1; }
    @media (min-width: 650px) {
      .photocallout > :last-child {
        flex: 0 0 268px;
        order: 2; } }

/*
Proof

Markup:
<article class="proof l-wrap">
  <h1>Proven to Work</h1>
  <p>Children in Between was created by distinguished psychologist Dr. Donald Gordon and proven to work.</p>
  <ul>
    <li><p>22% <span>Less child reported stress</span></p></li>
    <li><p>70% <span>Fewer school absences</span></p></li>
    <li><p>54% <span>Fewer doctor visits</span></p></li>
    <li><p>9/10 <span>Parents recommend</span></p></li>
  </ul>
</article>

Styleguide 4.11
*/
.proof {
  text-align: center; }
  .proof h1 {
    line-height: 1;
    margin-bottom: 9px; }
  .proof > p {
    color: #696969;
    line-height: 1.5;
    margin-bottom: 9px; }
  .proof ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .proof li {
    padding: 0 4.5px 9px; }
    .proof li p {
      align-items: center;
      background: #155a76;
      border-radius: 100%;
      box-sizing: border-box;
      color: #fff;
      display: flex;
      flex-direction: column;
      font-size: 36px;
      height: 138px;
      justify-content: center;
      line-height: 1;
      margin-bottom: 0;
      padding: 9px;
      width: 138px; }
    .proof li span {
      font-size: 14px;
      line-height: 1.618; }
  @media (min-width: 650px) {
    .proof > p {
      font-size: 18px;
      margin-bottom: 18px; }
    .proof li {
      padding: 0 18px; } }

/*
Screens

Markup:
<article class="screens l-wrap">
  <h1>Optimized for all screen sizes:</h1>
  <ul>
    <li>
      <img src="/images/screens-desktop.svg" alt="Desktop screen" width="123" height="100">
      Desktop
    </li>
    <li>
      <img src="/images/screens-laptop.svg" alt="Laptop screen" width="134" height="77">
      Laptop
    </li>
    <li>
      <img src="/images/screens-tablet.svg" alt="Tablet screen" width="50" height="64">
      Tablet
    </li>
    <li>
      <img src="/images/screens-mobile.svg" alt="Mobile screen" width="22" height="43">
      Mobile
    </li>
  </ul>
</article>

Styleguide 4.12
*/
.screens h1 {
  margin-bottom: 9px;
  text-align: center; }

.screens ul {
  align-items: flex-end;
  display: flex;
  justify-content: center; }

.screens li {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 4.5px; }
  .screens li:nth-child(1) img {
    max-width: 83px; }
  .screens li:nth-child(2) img {
    max-width: 84px; }
  .screens li:nth-child(3) img {
    max-width: 35px; }
  .screens li:nth-child(4) img {
    max-width: 17px; }

.screens img {
  display: block;
  height: auto;
  margin-bottom: 4.5px; }

@media (min-width: 650px) {
  .screens li {
    padding: 18px; }
    .screens li:nth-child(1) img, .screens li:nth-child(2) img, .screens li:nth-child(3) img, .screens li:nth-child(4) img {
      max-width: none; } }

/*
Topics

Markup:
<article class="topics l-wrap">
  <h1>What's Covered</h1>
  <ul>
    <li>Money Problems</li>
    <li>Long Distance Parenting</li>
    <li>Children Carrying Messages</li>
    <li>Quizzing Children About the Other Parent</li>
    <li>Putting Down the Other Parent</li>
    <li>Never Married Parents</li>
  </ul>
</article>

Styleguide 4.13
*/
.topics {
  background: url("images/topics.png") center center no-repeat;
  background-size: cover;
  color: #fff;
  padding-bottom: 36px;
  padding-top: 36px; }
  .topics h1 {
    font-size: 24px;
    margin-bottom: 36px; }
  .topics li {
    border: 1px solid #fff;
    box-sizing: border-box;
    font-size: 18px;
    padding: 18px;
    text-align: center; }
    .topics li:not(:last-child) {
      margin-bottom: 18px; }
  @media (min-resolution: 2dppx) {
    .topics {
      background-image: url("images/topics-2x.png"); } }
  @media (min-width: 960px) {
    .topics {
      background-image: url("images/topics-desktop.png");
      padding-bottom: 72px;
      padding-top: 54px; }
      .topics h1 {
        margin-bottom: 18px; }
      .topics ul {
        column-count: 2;
        column-gap: 36px; } }
  @media (min-width: 960px) and (min-resolution: 2dppx) {
    .topics {
      background-image: url("images/topics-desktop-2x.png"); } }

/*
Signup

For use on the PPC landing pages. Best viewed on their own page (outside of SC5's interface.)

Markup:
<section class="l-wrap wrap wrap-branddark signup">
  <div>
    <h1>Children in Between</h1>
    <p>A co-parenting program for divorcing and separating parents</p>
    <div class="cta">
      <a href="" class="button button-ppc">Sign-Up Now</a>
      <p>Guranteed Acceptance in Maricopa County, Arizona!</p>
    </div>
  </div>
  <div>
    <p class="seal">Instant Certificate Upon Completion!</p>
  </div>
</section>

Styleguide 4.1
*/
.signup {
  font-size: 18px;
  line-height: 1.2; }
  @media (min-width: 650px) {
    .signup {
      align-items: top;
      display: flex;
      padding-bottom: 54px;
      padding-top: 54px; } }
  .signup h1 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0; }
    @media (min-width: 650px) {
      .signup h1 {
        font-size: 48px; } }
  .signup p {
    font-size: 18px; }
    @media (min-width: 650px) {
      .signup p {
        font-size: 24px; } }
  .signup dl {
    display: flex;
    flex-wrap: wrap; }
  .signup dd,
  .signup dt {
    box-sizing: border-box;
    flex: 1 1 50%;
    max-width: 50%; }
  .signup dt {
    padding-right: 4.5px;
    text-align: right; }
  .signup dd {
    font-weight: bold;
    padding-left: 4.5px; }
  @media (min-width: 650px) {
    .signup ul {
      margin-bottom: 36px; } }
  .signup ul li {
    font-size: 14px;
    list-style: disc;
    margin: 0 0 4.5px 1em; }
    @media (min-width: 650px) {
      .signup ul li {
        font-size: 18px; } }
  .signup .cta {
    margin: 18px 0; }
    @media (min-width: 650px) {
      .signup .cta {
        align-items: center;
        display: flex; }
        .signup .cta > :first-child {
          margin-right: 18px; } }
    .signup .cta p {
      font-size: 12px;
      font-weight: bold;
      padding-top: 4.5px;
      text-align: center; }
      @media (min-width: 650px) {
        .signup .cta p {
          font-size: 18px;
          padding-top: 0;
          text-align: left; } }
  .signup .signup-x-text {
    padding: 0 18px 0 0; }

/*
Signup Secondary

Markup:
<section class="l-wrap wrap signup signup-secondary">
  <div>
    <h1>Why Choose Children in Between Online?</h1>
    <ul>
      <li>It’s not necessary to attend an in-person class</li>
      <li>The lowest cost online class available in Maricopa County</li>
      <li>Takes just 4 hours to complete</li>
      <li>Instant certification upon completion</li>
      <li>Available 24/7 to fit your schedule</li>
    </ul>
    <div class="cta">
      <a href="" class="button button-ppc">Sign-Up Now</a>
      <p><span>$45.95</span> for 30-day access</p>
    </div>
  </div>
  <div>
    <img src="/images/placeholder-landingpage.svg" alt="{county name} image">
    <dl>
      <dt>County: </dt>
      <dd>Maricopa</dd>
      <dt>State: </dt>
      <dd>Arizona</dd>
    </dl>
  </div>
</section>

Styleguide 4.1.1
*/
.signup-secondary {
  display: flex;
  flex-direction: column; }
  @media (min-width: 650px) {
    .signup-secondary {
      align-items: flex-start;
      flex-direction: row; } }
  .signup-secondary h1 {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 9px; }
    @media (min-width: 650px) {
      .signup-secondary h1 {
        font-size: 36px;
        margin-bottom: 18px; } }
  .signup-secondary img {
    display: block;
    margin: 0 auto 18px; }
  .signup-secondary > :first-child {
    order: 2; }
    @media (min-width: 650px) {
      .signup-secondary > :first-child {
        flex: 1 1 auto; } }
  .signup-secondary > :last-child {
    margin-bottom: 18px;
    order: 1; }
    @media (min-width: 650px) {
      .signup-secondary > :last-child {
        flex: 0 1 164px;
        margin-bottom: 0;
        margin-right: 36px; } }
  .signup-secondary .cta p {
    font-size: 14px;
    margin-top: 9px;
    text-align: center; }
    @media (min-width: 650px) {
      .signup-secondary .cta p {
        margin-top: 0; } }
  .signup-secondary .cta span {
    color: #155a76;
    display: block;
    font-size: 36px; }
  .signup-secondary .signup-x-text {
    padding: 0; }

.survey .progressbar {
  display: none; }
  @media (min-width: 960px) {
    .survey .progressbar {
      display: flex; } }

.onboarding-notifications {
  padding-left: 18px;
  padding-right: 18px; }
  .onboarding-notifications h2 {
    line-height: 1.2em;
    margin-top: 1em; }
  .onboarding-notifications h3 {
    margin-top: .5em; }
  .onboarding-notifications p {
    margin-bottom: 1em;
    font-size: 16px; }

.horizontal-list li {
  float: left;
  list-style-image: none;
  list-style-type: none;
  margin-left: 0;
  white-space: nowrap; }
  .horizontal-list li:first-child {
    padding-left: 0; }
  .horizontal-list li:last-child {
    padding-right: 0; }

.font-size {
  font-size: 18px; }

/*
Demos

Styleguide 5.0
*/
/*
Homepage

Markup:
<div class="l-wrappermobile">
  <sg-insert>3.3</sg-insert>
  <div class="l-childrenvertical">
    <sg-insert>4.6</sg-insert>
    <sg-insert>4.7</sg-insert>
    <sg-insert>4.7.1</sg-insert>
    <sg-insert>4.12</sg-insert>
    <sg-insert>4.9</sg-insert>
    <sg-insert>4.4</sg-insert>
    <sg-insert>4.13</sg-insert>
    <sg-insert>4.11</sg-insert>
    <sg-insert>4.8</sg-insert>
    <sg-insert>4.5</sg-insert>
  </div>
  <sg-insert>3.9</sg-insert>
</div>

Styleguide 5.1
*/
/*
Receipt

Markup:
<div class="l-receipt">
  <div class="receiptheader">
    <div>
      <p class="receiptheader-x-name">John Doe</p>
      <table class="printtable printtable-small">
        <thead class="visuallyhidden">
          <tr>
            <th scope="row">Direction</th>
            <th scope="row">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Re:</th>
            <td>You’ve completed your purchase!</td>
          </tr>
          <tr>
            <th scope="row">To:</th>
            <td>Namey Namerson</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="receiptheader-x-meta">
      <p class="receiptheader-x-time">9:34am</p>
      <div class="receiptheader-x-avatar">AB</div>
    </div>
  </div>
  <div class="l-receipt-x-body">
    <h1 class="receiptheading">Purchase Complete!</h1>
    <h2 class="receiptheading receiptheading-small">Account Information</h2>
    <table class="printtable">
      <thead class="visuallyhidden">
        <tr>
          <th scope="row">Information</th>
          <th scope="row">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">Customer Name</th>
          <td>Namey Namerson</td>
        </tr>
        <tr>
          <th scope="row">Username</th>
          <td>paypal@test.com</td>
        </tr>
        <tr>
          <th scope="row">Expiration Date</th>
          <td>November 20, 2016</td>
        </tr>
      </tbody>
    </table>
    <h2 class="receiptheading receiptheading-small">Purchased Products</h2>
    <table class="printtable printtable-span">
      <thead>
        <tr>
          <th scope="row">Product</th>
          <th scope="row">Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>CDE's Children in Between Online 30-day Account</td>
          <td>$45.95</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

Styleguide 5.2
*/
/*
Purchased Items

Markup:
<div class="l-childrenvertical l-childrenvertical-small">
  <sg-insert>3.56</sg-insert>
  <div class="pagetitle">
    <h1>Purchased Items</h1>
  </div>
  <sg-insert>3.57</sg-insert>
</div>

Styleguide 5.3
*/
/*
Results

Markup:
<div class="l-containerhorizontal l-containerhorizontal-small l-containerhorizontal-is-minpad l-containervertical l-childrenvertical l-childrenvertical">
  <div class="bordered">
    <header class="page-title page-title-centered">
      <img src="http://placehold.it/112x86" alt="Results certificate icon">
      <h1>Congratulations John Blimb</h1>
      <p>You have passed all six chapters, and the final exam with a cumulative score of <strong>0%</strong>.</p>
    </header>
    <form class="form form-nomargin">
      <div class="form-row">
        <hr class="rule">
      </div>
      <div class="form-row">
        <h4>Add your Case Number</h4>
        <p class="u-text-small">If you have begun the court process, please enter your case number below. It will be printed on your certificate.</p>
      </div>
      <div class="form-row">
  			<p class="label">Filing County:</p>
  			<p class="value"><input type="text" disabled="disabled" placeholder="California, San Bernardino County"></p>
  		</div>
      <div class="form-row">
  			<p class="label">Case Number:</p>
  			<div class="value"><input type="text" name="case_number" value="Q-4M-4C-05" placeholder="Enter your case number" id="id_case_number" maxlength="200"></div>
  		</div>
      <div class="email-results">
        <div class="submit">
          <input class="button" type="submit" name="downoad" value="Download Certificate">
          <button class="button" onclick="document.querySelector('#email-results-form').style.display = 'block'" type="button">Email Certificate</button>
        </div>
        <div id="email-results-form" style="display: none">
          <div class="form-row">
            <h4>Email a PDF certificate to:</h4>
          </div>
          <div class="form-row">
      			<p class="label">Name:</p>
      			<div class="value">
              <input type="text" name="name" placeholder="Enter name" id="name" maxlength="200">
            </div>
      		</div>
          <div class="form-row">
      			<p class="label">Email:</p>
      			<div class="value">
              <input type="email" name="email" placeholder="Enter email" id="email" maxlength="200">
            </div>
      		</div>
          <div class="form-row checkbox">
            <div class="value">
              <input type="checkbox" name="email_copy" id="email_copy">
            </div>
            <label for="email_copy">
              <p>Email me a copy of my certificate</p>
            </label>
          </div>
          <div class="form-row form-row-submit">
            <div class="form-row-submit-x-button">
              <button class="button" onclick="document.querySelector('.form-row-submit').classList.add('form-row-submit-is-success')" type="button">Send Email</button>
            </div>
            <div class="form-row-submit-x-message">Email Sent!</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div>
    <h4>Filing your certificate of completion:</h4>
    <p class="u-text-small">The Maricopa County Court no longer accepts paper certificates. <br>The Center for Divorce Education will eFile your Certificate with the Maricopa Court. <br>Enter your case number in the box above, or the court will not accept your Certificate. <br>You should download and/or email yourself a copy of the certificates for your records.</p>
  </div>
  <div class="l-childrenvertical">
    <h1>You may also like&hellip;<h1>
    <sg-insert>3.59</sg-insert>
    <sg-insert>3.59</sg-insert>
    <sg-insert>3.59</sg-insert>
  </div>
</div>

Styleguide 5.4
*/
